import React, { useEffect, useRef } from "react";
// Import Swiper React components
import { TfiLocationPin } from "react-icons/tfi";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
// import required modules
import { Link } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useState } from "react";
import { MdOutlineMyLocation } from "react-icons/md";
import { userLocation } from "../../../utils/userProfileSlice";
import { useDispatch } from "react-redux";

const SearchSection = ({
  showSearch,
  userlocation,
  // setGeometryLocation
}) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [productList, setProductList] = useState([]);
  const [marker, setMarker] = useState({
    lat: userlocation?.lat,
    lng: userlocation?.lng,
  });
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY; // Replace with your API key
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userlocation?.lat},${userlocation?.lng}&key=${apiKey}`;

    // Make a request to the Google Maps Geocoding API
    fetch(geocodingApiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK" && data.results.length > 0) {
          const results = data.results[0].address_components;

          // Find the component with type "locality" (city)
          const cityComponent = results.find((component) =>
            component.types.includes("locality")
          );

          // Find the component with type "administrative_area_level_1" (state or province)
          const stateComponent = results.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          // Find the component with type "sublocality_level_1" (sublocality level 1)
          const sublocality1Component = results.find((component) =>
            component.types.includes("sublocality_level_1")
          );

          // Find the component with type "sublocality_level_2" (sublocality level 2)
          const sublocality2Component = results.find((component) =>
            component.types.includes("sublocality_level_2")
          );

          // Extract the city, state/province, sublocality level 1, and sublocality level 2
          const city = cityComponent ? cityComponent.long_name : "";
          const state = stateComponent ? stateComponent.long_name : "";
          const sublocality1 = sublocality1Component
            ? sublocality1Component.long_name
            : "";
          // const sublocality2 = sublocality2Component
          //   ? sublocality2Component.long_name
          //   : "";

          // Combine the components for a more complete location name
          const locationName = [sublocality1, city, state]
            .filter(Boolean)
            .join(", ");

          // localStorage.setItem("locationName", locationName);
          setSearchInput(locationName);
          //   // window.location.reload();
        } else {
          console.error("Geocoding API request failed");
        }
      })
      .catch((error) => {
        console.error("Error getting location name: " + error.message);
      });
  }, []);

  const handleLocationInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    // Call the Google Maps Places API to get autocomplete predictions
    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: input,
        componentRestrictions: { country: "IN" }, // Filter results to India
      },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSearchResults(predictions);
        } else {
          setSearchResults([]);
        }
      }
    );
  };

  const handleSelectPlace = (placeId, description) => {
    if (description === "Detect your location") {
      // Ask the user to enable their location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(
              { location: { lat: latitude, lng: longitude } },
              (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                  if (results[0]) {
                    setSearchInput(results[0].formatted_address);
                    setSearchResults([]); // Clear the search results
                  }
                }
              }
            );
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported");
      }
    } else {
      // Call the Google Maps Places API to get details of the selected place
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Do something with the selected place details
          const { lat, lng } = place.geometry.location;
          setMarker({ lat: lat(), lng: lng() });
          const location = {
            lat: lat(),
            lng: lng(),
          };

          dispatch(userLocation(location));
          localStorage.setItem("location", JSON.stringify(location));
          // setGeometryLocation({ lat: lat(), lng: lng() });
        }
      });

      setSearchInput(description); // Set the selected place's description as the search input
      setSearchResults([]); // Clear the search results
    }
  };
  const handleClearInput = () => {
    setSearchInput("");
    inputRef.current.focus();
  };

  useEffect(() => {
    // Close the dropdown when the user clicks anywhere outside of it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchResults([]);
        setProductList([]);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Items Search Functionality

  // const fetchProducts = async (query) => {
  //   try {
  //     // Make an API request to fetch products based on the query
  //     const response = await fetch(` https://api.escuelajs.co/api/v1/products`);
  //     const data = await response.json();

  //     // Update the product list with the response data
  //     setProductList(data);
  //   } catch (error) {
  //     setProductList([]);
  //     console.log("Error occurred while fetching products:", error);
  //   }
  // };

  const handleItemsInputChange = (event) => {
    const input = event.target.value;
    setSearchQuery(input);

    // fetchProducts();

    // // Create copy of item list
    // var updatedList = [...productList];
    // console.log("productList", productList);

    // // Include all elements which includes the search query
    // updatedList = updatedList.filter((item) => {
    //   return item?.title?.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    // });
    // console.log("updatedList", updatedList);

    // setProductList(updatedList);
  };

  // const handleSelectItems = (productId, productName) => {
  //   setSearchQuery(productName);
  //   setProductList([]);
  // };

  const handleClearInputItems = () => {
    setSearchQuery("");
    inputRef.current.focus();
  };

  return (
    <div>
      <div className="mx-6">
        {/* <form onSubmit={handleSubmit}> */}
        <div className="home-search flex w-[100%] flex-col items-center justify-center px-3">
          <div className="search-bar flex flex-col items-center justify-center xs:flex-wrap sm:flex-col lg:flex-row lg:flex-nowrap">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <TfiLocationPin className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </div>

              <div className="flex items-center">
                <input
                  ref={inputRef}
                  type="text"
                  autoComplete="off"
                  id="simple-search"
                  value={searchInput}
                  onChange={handleLocationInputChange}
                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-9 p-2.5 ${
                    showSearch ? "w-48 lg:w-60" : " w-72 md:w-96"
                  } transition-all duration-300 ease-in-out`}
                  placeholder="Search Location"
                />
                {searchInput && (
                  <button
                    className="absolute right-0 mr-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={handleClearInput}
                  >
                    <RxCross2 className="w-4 h-4" />
                  </button>
                )}
              </div>

              {searchResults.length > 0 && (
                <ul
                  ref={dropdownRef}
                  className="absolute max-h-80 overflow-y-scroll left-0 z-20 w-full mt-2 bg-white border border-gray-300 rounded shadow-md"
                >
                  <li
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() =>
                      handleSelectPlace(null, "Detect your location")
                    }
                  >
                    <div className="relative">
                      <MdOutlineMyLocation className="absolute mt-1 text-gray-400 w-5 h-5" />
                      <div className="ml-7">Detect your location</div>
                    </div>
                  </li>
                  {searchResults.map((result) => (
                    <li
                      key={result.place_id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-gray-500"
                      onClick={() =>
                        handleSelectPlace(result.place_id, result.description)
                      }
                    >
                      <div className="relative">
                        <TfiLocationPin className="absolute mt-1 text-gray-400 w-5 h-5" />
                        <div className="ml-7">{result.description}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="mx-2 my-2"></div>

            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </div>

              <div className="flex items-center">
                <input
                  ref={inputRef}
                  type="text"
                  autoComplete="off"
                  id="simple-search"
                  value={searchQuery}
                  onChange={handleItemsInputChange}
                  className={` bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5 ${
                    showSearch ? "w-48 lg:w-80" : " w-72 md:w-96"
                  } transition-all duration-300 ease-in-out`}
                  placeholder="Search Items"
                />
                {searchQuery && (
                  <button
                    className="absolute right-0 mr-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={handleClearInputItems}
                  >
                    <RxCross2 className="w-4 h-4" />
                  </button>
                )}

                {showSearch && (
                  <Link
                    to={`/lists?lat=${marker?.lat}&lng=${marker?.lng}&item=${searchQuery}&tab=Search-NearBy`}
                  >
                    <button
                      // type="submit"
                      className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                    >
                      <AiOutlineSearch className="w-5 h-5" />
                      <span className="sr-only">Search</span>
                    </button>
                  </Link>
                )}
              </div>

              {/* {productList.length > 0 && (
                <ul
                  ref={dropdownRef}
                  className="absolute max-h-80 overflow-y-scroll left-0 z-20 w-full mt-2 bg-white border border-gray-300 rounded shadow-md"
                >
                  {productList.map((result) => (
                    <li
                      key={result.id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-gray-500"
                      onClick={() => handleSelectItems(result.id, result.title)}
                    >
                      <div className="relative">
                        <AiOutlineSearch className="absolute mt-1 text-gray-400 w-5 h-5" />
                        <div className="ml-7">{result.title}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              )} */}
            </div>
          </div>

          {!showSearch && (
            <div aria-label="logo" rel="noopener">
              <Link
                to={`/lists?lat=${marker?.lat}&lng=${marker?.lng}&item=${searchQuery}&tab=Search-NearBy`}
              >
                <Button
                  text="Search"
                  className="my-3 inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
