import React from "react";

const RefundAndCancellationPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 underline flex justify-center items-center ">
          Refund and Cancellation Policy
        </h1>

        <p className="text-base text-justify leading-normal text-gray-700 bg-white">
          <span className="font-open-sans ">
            Our focus is complete User satisfaction. In the event, if you are
            displeased with the services provided, we will refund back the
            money, provided the reasons are genuine and proved after
            investigation. Please read the fine prints of each deal before
            buying it; it provides all the details about the services or the
            product you purchase.
          </span>
        </p>

        <p className="text-base text-justify mt-5 leading-normal text-gray-700 bg-white">
          <span className="font-open-sans ">
            In case of dissatisfaction from our services, clients have the
            liberty to cancel their projects and request a refund from us within
            48 hours from the time the service was availed. Our Policy for the
            cancellation and refund will be as follows:
          </span>
        </p>

        <p className="text-base leading-normal mt-5 text-gray-700 bg-white">
          <strong className="font-open-sans ">
            Cancellation Policy
          </strong>
        </p>

        <p className="text-base text-gray-700 bg-white">
          <br />
        </p>

        <p className="text-base text-justify leading-normal text-gray-700 bg-white">
          <span className="font-open-sans ">
            For cancellations, please contact us with:&nbsp;
          </span>
          <strong className="font-open-sans ">
            Email: makeassureservices@gmail.com
          </strong>
        </p>

        <p className="text-base text-justify mt-5 leading-normal text-gray-700 bg-white">
          <span className="font-open-sans ">
            Requests received later than 48 hours prior to the end of the
            current service period will be treated as cancellation of services
            for the next service period.
          </span>
        </p>

        <p className="text-base leading-normal mt-5 text-gray-700 bg-white">
          <strong className="font-open-sans ">
            Refund Policy
          </strong>
        </p>

        <ul className="list-disc pl-8 text-base text-gray-700 bg-white">
          <li className="mb-3">
            <span className="font-open-sans">
              The refund/cancellation process will be executed within 5-7
              business working days.
            </span>
          </li>
          <li className="mb-3">
            <span className="font-open-sans">
              In case any client is completely unsatisfied with our products, we
              can provide a refund.
            </span>
          </li>
          <li className="mb-3">
            <span className="font-open-sans">
              If paid by credit card, refunds will be issued to the original
              credit card provided at the time of purchase, and in case of
              payment gateway name payments, the refund will be made to the same
              account.
            </span>
          </li>
        </ul>

        <p className="mb-8">&nbsp;</p>
      </div>
    </div>
  );
};

export default RefundAndCancellationPolicy;
