import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="bg-gray-100 py-12 px-4">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-semibold mb-6 underline">
            MakeAssure Terms &amp; Conditions (T&amp;C)
          </h1>

          <p className="text-justify text-sm text-gray-600 mb-4">
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            document is published in accordance with the provisions of Rule 3
            (1) of the Information Technology (Intermediaries guidelines) Rules,
            2011 that require publishing the rules and regulations, privacy
            policy, and Terms of Use for access or usage of&nbsp;
            <a
              href="https://makeassure.com/"
              className="text-blue-500"
              target="_blank"
            >
              https://makeassure.com/
            </a>
            &nbsp;website.
          </p>

          <p className="text-justify text-sm text-gray-600 mb-4">
            The terms "We" / "Us" / "Our"/”Company” individually and
            collectively refer to&nbsp;
            <b className="text-blue-600">MakeAssure</b>&nbsp;and the terms
            "Visitor” ”User” refer to the users.
          </p>

          <p className="text-justify text-sm text-gray-600">
            This page states the Terms and Conditions under which you (Visitor)
            may visit this website (“https://makeassure.com/ ”). Please read
            this page carefully. If you do not accept the Terms and Conditions
            stated here, we would request you to exit this site. The business,
            any of its business divisions and / or its subsidiaries, associate
            companies or subsidiaries to subsidiaries or such other investment
            companies (in India or abroad) reserve their respective rights to
            revise these Terms and Conditions at any time by updating this
            posting. You should visit this page periodically to re-appraise
            yourself of the Terms and Conditions, because they are binding on
            all users of this Website.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            USE OF CONTENT
          </h1>

          <p className="mt-4">
            All logos, brands, marks headings, labels, names, signatures,
            numerals, shapes or any combinations thereof, appearing in this
            site, except as otherwise noted, are properties either owned, or
            used under licence, by the business and / or its associate entities
            who feature on this Website. The use of these properties or any
            other content on this site, except as provided in these terms and
            conditions or in the site content, is strictly prohibited.
          </p>

          <p className="mt-4">
            You may not sell or modify the content of this Website or reproduce,
            display, publicly perform, distribute, or otherwise use the
            materials in any way for any public or commercial purpose without
            the respective organisation’s or entity’s written permission.
          </p>

          <h1 className="text-2xl font-semibold my-6 underline">
            ACCEPTABLE WEBSITE USE
          </h1>

          <h2 className="text-xl font-semibold my-6 ml-8 underline">
            (A) Security Rules
          </h2>
          <p className="mt-4 ml-12">
            Visitors are prohibited from violating or attempting to violate the
            security of the Web site, including, without limitation,
          </p>

          <ul className="list-disc pl-6 mt-6">
            <ol>
              (1) accessing data not intended for such user or logging into a
              server or account which the user is not authorised to access,
            </ol>
            <ol>
              (2) attempting to probe, scan or test the vulnerability of a
              system or network or to breach security or authentication measures
              without proper authorisation,
            </ol>
            <ol>
              (3) attempting to interfere with service to any user, host or
              network, including, without limitation, via means of submitting a
              virus or &quot;Trojan horse&quot; to the Website, overloading,
              &quot;flooding&quot;, &quot;mail bombing&quot; or
              &quot;crashing&quot;, or
            </ol>
            <ol>
              (4) sending unsolicited electronic mail, including promotions
              and/or advertising of products or services. Violations of system
              or network security may result in civil or criminal liability. The
              business and / or its associate entities will have the right to
              investigate occurrences that they suspect as involving such
              violations and will have the right to involve, and cooperate with,
              law enforcement authorities in prosecuting users who are involved
              in such violations.
            </ol>
          </ul>

          <h2 className="text-xl font-semibold my-6 ml-8 underline">
            (B) General Rules
          </h2>
          <p className="mt-4 ml-12">
            Visitors may not use the Web Site in order to transmit, distribute,
            store or destroy material
          </p>

          <ul className="list-disc pl-6 mt-6">
            <ol>
              (1) that could constitute or encourage conduct that would be
              considered a criminal offence or violate any applicable law or
              regulation
            </ol>
            <ol>
              (2) in a manner that will infringe the copyright, trademark, trade
              secret or other intellectual property rights of others or violate
              the privacy or publicity of other personal rights of others, or
            </ol>
            <ol>
              (3) that is libellous, defamatory, pornographic, profane, obscene,
              threatening, abusive or hateful.
            </ol>
          </ul>

          <h1 className="text-2xl font-semibold mb-6 underline">INDEMNITY</h1>

          <p className="mt-4">
            The User unilaterally agree to indemnify and hold harmless, without
            objection, the Company, its officers, directors, employees and
            agents from and against any claims, actions and/or demands and/or
            liabilities and/or losses and/or damages whatsoever arising from or
            resulting from their use of https://makeassure.com/ or
            Our MakeAssure Mobile App or their breach of the terms.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">LIABILITY</h1>

          <p className="mt-4">
            User agrees that neither Company nor its group companies, directors,
            officers or employee shall be liable for any Direct or/and indirect,
            incidental, special, consequential, exemplary damages, resulting
            from the use or/and the inability to use of the service. For cost of
            procurement of substitute goods or/and services resulting from any
            goods. Services purchased, obtained, messages received, transactions
            entered into through or/and from the service resulting from
            unauthorized access to alteration of user&#39;s transmissions of
            data arising from any other matter relating to the service,
            including but not limited to, damages for loss of profits, even if
            Company has been advised of the possibility of such damages.
          </p>

          <p className="mt-4">
            User further agrees that Company shall not be liable for any damages
            arising from interruption, suspension or termination of service,
            including but not limited to direct, indirect, incidental, special
            consequential, exemplary damages, whether such interruption or/and
            suspension or/and termination was justified or not, negligent or
            intentional, inadvertent or advertent.
          </p>
          <p className="mt-4">
            User agrees that Company shall not be responsible or liable to user,
            or anyone, for the statements or conduct of any third party of the
            service. In sum, in no event shall Company&#39;s total liability to
            the User for all damages or/and losses or/and causes of action
            exceed the amount paid by the User to Company, if any, that is
            related to the cause of action.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            DISCLAIMER OF CONSEQUENTIAL DAMAGES
          </h1>

          <p className="mt-4">
            In no event shall Company or any parties, organizations or entities
            associated with the corporate brand name us or otherwise, mentioned
            at this Website be liable for any damages whatsoever (including,
            without limitations, incidental and consequential damages, lost
            profits, or damage to computer hardware or loss of data information
            or business interruption) resulting from the use or inability to use
            the Website and the Website material, whether based on warranty,
            contract, tort, or any other legal theory, and whether or not, such
            organization or entities were advised of the possibility of such
            damages.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            MakeAssure T&amp;C:
          </h1>

          <ol className="list-disc pl-6 mt-6">
            <li>
              MakeAssure is an online platform that offers a scope preparing a
              page about local brands, business and services.
            </li>
            <li>
              Only legal Goods/ Services related post like photos or videos can
              be published.
            </li>
            <li>
              No illegal things or service concerning thing can’t be posted in
              this website/App.
            </li>
            <li>
              The photo and videos posted in this App/Website are not examined
              for it’s proprietorship/patent
            </li>
            <li>
              The information such as mobile no, email id, account number or
              Address are secured while opening the page and hasn’t shared with
              any other third party.
            </li>
            <li>
              The information such as mob. No, email id, addresses are only for
              the purpose of the betterment and enhance the service level of the
              platform/Website and to provide a smooth service to the
              vendors/service providers registered in the platform.
            </li>
            <li>
              No other financial transactions are encouraged except promotion,
              Advertisement, Verification or post listing fees. So the users are
              requested to make personal investigation before making any
              monetary transaction.
            </li>
            <li>
              The website prohibits the exposition photo/videos or property that
              comes under other copyright. Hence the user is requested to
              post/Promote their own Copyright or authorised materials/goods.
            </li>
            <li>
              MakeAssure holds the right to discard/delete any
              offensive/objectionable publishment made by any publisher.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
