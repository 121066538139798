import React, { useEffect, useState } from "react";
import { AiFillStar, AiTwotoneStar } from "react-icons/ai";
import { VscLiveShare } from "react-icons/vsc";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { Link, useParams } from "react-router-dom";
import Footer from "../../layouts/Footer/Footer";
import ShareDetailsModal from "../MyBusinessPage/Modal/shareDetailsModal";
import ChatPopup from "../../components/ChatPopup/chatPopup";
import ContactDetails from "../../common/contact-details/ContactDetails";
import Review from "../../common/Reviews/review";
import useVender from "../../utils/hooks/useVender";
import Loading from "../../common/Loading/Loading";
import ImageNotFound from "../../assets/images/image_not-available.png";
import { IoCallOutline } from "react-icons/io5";
import axios from "axios";
import { RiUserFollowFill } from "react-icons/ri";
import { BsPlusLg } from "react-icons/bs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginModal } from "../../utils/appSlice";
import { BiDownArrow } from "react-icons/bi";

const VenderDetailsPage = () => {
  const [sharePopup, setSharePopup] = useState(false);
  const [callOption, setCallOption] = useState(false);
  const [chatOption, setChatOption] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [allPosts, setAllPosts] = useState();
  const [reviewVenderSummaryData, setReviewVenderSummaryData] = useState();
  const [isReview, setIsReview] = useState(false);
  const [writeReviewPopup, setWriteReviewPopup] = useState(false);
  const { venId } = useParams();
  const venderData = useVender(venId);
  const getUserToken = () => JSON.parse(localStorage.getItem("userInfo")).token;
  const dispatch = useDispatch();
  // const userToken = localStorage.getItem("userInfo");
  // const userDetails = JSON.parse(userToken);
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));
  const [sharePageUrl, setSharePageUrl] = useState();

  const handleShareModal = (item) => {
    setSharePopup(true);
    setSharePageUrl(`vender-details/${item.businessPageId}`);
  };

  const toggleLoginHandler = () => {
    dispatch(loginModal());
  };

  useEffect(() => {
    if (venderData) {
      getVendorPosts();
    }
  }, [venderData]);

  async function getVendorPosts() {
    try {
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/vendor-products?vendorId=${venderData?.businessPageId}`,
        auth
      );

      const responseData = response.data;
      setAllPosts(responseData.data);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  useEffect(() => {
    if (venderData) {
      getCheckUserIsFollowing();
    }
  }, [venderData, isFollow]);

  async function getCheckUserIsFollowing() {
    try {
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          // "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/is-vendor-followed-by?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}`,
        auth
      );

      const responseData = response.data;
      if (responseData?.success) {
        setIsFollow(true);
      }
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  const followHandleSubmit = async () => {
    try {
      if (userDetails.customId === venderData.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/follow-to-vendor?vendorId=${venderData?.businessPageId}&userId=${userDetails.customId}`,

        { vendorId: venderData?.businessPageId, userId: userDetails.customId },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );

      const { data } = response.data;
      setIsFollow(true);
      toast.success(response.data.message);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const unFollowHandleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/unfollow-to-vendor?vendorId=${venderData?.businessPageId}&userId=${userDetails.customId}`,

        { vendorId: venderData?.businessPageId, userId: userDetails.customId },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      setIsFollow(false);
      toast.success(response.data.message);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // Rating vender Summary

  useEffect(() => {
    getVenderReviewSummary();
  }, [isReview]);

  async function getVenderReviewSummary() {
    try {
      const auth = {
        headers: {
          Authorization: getUserToken(),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/vendor-rating-summary?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}`,
        auth
      );

      const responseData = response.data;
      setReviewVenderSummaryData(responseData);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  async function postRatingHandler(stars) {
    try {
      if (userDetails.customId === venderData.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/vendor-rating?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}&rating=${stars}`,
        {
          userId: userDetails.customId,
          vendorId: venderData?.businessPageId,
          rating: stars,
        },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      setIsReview(true);
      setWriteReviewPopup(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  }

  return !venderData ? (
    <Loading />
  ) : (
    <>
      {chatOption && <ChatPopup setChatOption={setChatOption} />}
      {callOption && (
        <ContactDetails setCallOption={setCallOption} venderData={venderData} />
      )}
      {sharePopup && (
        <ShareDetailsModal
          setSharePopup={setSharePopup}
          sharePageUrl={sharePageUrl}
        />
      )}

      <section className="pb-16 xs:mx-8 sm:mx-16 ">
        <div className="mx-auto max-w-[1300px] px-2 sm:px-0">
          <header>
            <div className="my-4 flex flex-wrap justify-between lg:flex-nowrap ">
              <div className="relative md:mr-2 mb-4 w-full cursor-pointer lg:mb-0 lg:w-[70%]">
                <img
                  className="object-fill w-full h-80 rounded-lg shadow-lg"
                  src={venderData?.coverUrl}
                  alt="Not Found"
                />
              </div>
              <div className="relative md:ml-2 mb-4 w-full cursor-pointer lg:mb-0 lg:w-[30%]">
                <img
                  className="object-fill w-full h-80 rounded-lg shadow-lg"
                  src={venderData?.profileUrl}
                  alt="Not Found"
                />
              </div>
            </div>

            <div className="text-sm text-gray-800 flex justify-center items-center">
              <div>
                <div className="text-4xl leading-tight mb-4">
                  {venderData?.businessPageName}
                </div>
                {/* <!-- post, following, followers list for medium screens --> */}
                <ul className=" flex space-x-8 mb-4  justify-center items-center">
                  <li>
                    <div className="font-semibold flex justify-center items-center">
                      {venderData?.vendorPost}
                    </div>
                    <div className="flex justify-center items-center">Post</div>
                  </li>

                  <li>
                    <span className="font-semibold ">
                      <div className="flex justify-center items-center">
                        {venderData?.vendorRating}
                        <AiTwotoneStar className="text-yellow-300" size={20} />
                      </div>
                    </span>
                    <div className="flex justify-center items-center">
                      Page Rating
                    </div>
                  </li>
                  <li>
                    <span className="font-semibold flex justify-center items-center">
                      {venderData?.vendorFollower}
                    </span>
                    <div className="flex justify-center items-center">
                      Followers
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </header>

          {/* <!-- insta freatures --> */}
          <ul className="flex items-center justify-center uppercase tracking-widest font-semibold text-xs text-gray-600 ">
            <div className="flex justify-center items-center mt-5">
              <ul className="md:flex md:space-x-8 mb-4">
                <li>
                  {isFollow ? (
                    <button
                      type="button"
                      className="mb-2 flex bg-[#3b454b] rounded w-36  justify-center items-center  py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      onClick={unFollowHandleSubmit}
                    >
                      Following
                      <RiUserFollowFill size={18} className="mr-2" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="mb-2 flex bg-[#1da1f2] rounded w-36  justify-center items-center  py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      onClick={
                        userDetails ? followHandleSubmit : toggleLoginHandler
                      }
                    >
                      Follow
                      <BsPlusLg size={15} className="ml-2" />
                    </button>
                  )}
                </li>

                <li>
                  <button
                    type="button"
                    className="mb-2 flex  justify-center items-center bg-[#1da1f2] rounded w-36 px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg "
                    onClick={
                      userDetails
                        ? () => setCallOption(!callOption)
                        : toggleLoginHandler
                    }
                  >
                    <IoCallOutline size={18} className="mr-2" />
                    Contact
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="mb-2 flex justify-center items-center bg-[#1da1f2] rounded w-36 px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                    onClick={
                      userDetails
                        ? () => setChatOption(!chatOption)
                        : toggleLoginHandler
                    }
                  >
                    <HiOutlineChatBubbleBottomCenterText
                      size={18}
                      className="mr-2"
                    />
                    Chat
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="mb-2 flex justify-center items-center bg-[#1da1f2] rounded w-36 px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                    onClick={() => handleShareModal(venderData)}
                  >
                    <VscLiveShare size={18} className="mr-2" />
                    Share
                  </button>
                </li>
              </ul>
            </div>
          </ul>

          <div className="mt-10 flex flex-wrap lg:flex-nowrap border-2 rounded-lg border-gray-200 p-4">
            <div className="w-full">
              <h5 className="text-lg">About the Business</h5>
              <div className="my-4 max-h-56 overflow-y-scroll max-w-4xl leading-[1.8rem] text-[#6d6d6d]">
                {venderData?.pageDescription}
              </div>
              <div>
                <h5 className="text-lg">Contact Details</h5>
              </div>
              <div className="contact-details mt-4 mb-6 flex w-full flex-wrap">
                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Business Email : &nbsp;
                    </h6>
                    <span
                      className={`inline-block text-sm font-semibold ${
                        !userDetails && "blur-sm"
                      }`}
                    >
                      {venderData?.businessEmail}
                    </span>
                  </div>
                </div>

                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Page Catagory :&nbsp;
                    </h6>
                    <span className="inline-block text-sm font-semibold">
                      {venderData?.pageCategory}
                    </span>
                  </div>
                </div>

                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Mobile Number :&nbsp;
                    </h6>
                    <span
                      className={`inline-block text-sm font-semibold ${
                        !userDetails && "blur-sm"
                      }`}
                    >
                      {venderData?.businessContactNumber}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <div className="border-b border-gray-900/10">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Social media Links
                  </h2>
                </div>

                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Facebook :&nbsp;
                    </h6>
                    <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                      {venderData?.facebookUrl}
                    </span>
                  </div>
                </div>

                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Instagram :&nbsp;
                    </h6>
                    <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                      {venderData?.instagramUrl}
                    </span>
                  </div>
                </div>

                <div className="mr-0 sm:mr-32">
                  <div className="my-2">
                    <h6 className="inline-block text-sm text-gray-800">
                      Youtube :&nbsp;
                    </h6>
                    <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                      {venderData?.youtubeUrl}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center mb-5 gap-5 flex-wrap lg:flex-nowrap ">
              <div className="ml-0 lg:ml-5 relative">
                <div
                  className={`h-fit w-[300px] rounded-xl border-2 border-gray-300 shadow-md sm:w-[350px] ${
                    !userDetails &&
                    "blur-sm rounded-xl border-2 border-blue-600"
                  }`}
                >
                  <iframe
                    title="location"
                    className="relative m-auto w-[300px] sm:w-[350px]"
                    src={`https://maps.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16&output=embed`}
                    width="350"
                    height="200"
                    // frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                  <div className="p-4">
                    <div
                      className="group flex flex-col  rounded-lg bg-gray-300 p-3 text-white"
                      tabindex="1"
                    >
                      <div className="flex cursor-pointer items-center justify-between">
                        <span className="font-bold text-black">Address</span>

                        <BiDownArrow
                          size={15}
                          className=" text-black  transition-all duration-500 group-focus:-rotate-180"
                        />
                      </div>
                      <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                        <span className="flex text-left text-xs font-medium leading-5 tracking-[0.3px] text-[#6d6d6d]">
                          {venderData?.streetAddress}, {venderData?.village},{" "}
                          {venderData?.city}, {venderData?.district},{" "}
                          {venderData?.country}, {venderData?.zip}
                        </span>
                      </div>
                    </div>
                    {/* <span className="font-bold text-black">Address</span>
                    <span className="flex text-left text-xs font-medium leading-5 tracking-[0.3px] text-[#6d6d6d]">
                      {venderData?.streetAddress}, {venderData?.village},{" "}
                      {venderData?.city}, {venderData?.district},{" "}
                      {venderData?.country}, {venderData?.zip}
                    </span> */}

                    <Link
                      to={`https://www.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16`}
                      target="_blank"
                    >
                      <button className="mt-2 w-full rounded-md bg-gray-300 py-2 px-[8px] text-sm font-bold text-blue-500">
                        Directions
                      </button>
                    </Link>
                  </div>
                </div>
                {!userDetails && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-80 opacity-0 transition-opacity hover:opacity-100">
                    <button
                      className="w-24 flex justify-center items-center text-xs sm:text-sm bg-slate-100 px-2 sm:px-3 py-2 text-blue-500 rounded font-medium shadow border focus:outline-none  hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                      onClick={toggleLoginHandler}
                    >
                      LOGIN
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="">
            <Review
              venderData={venderData}
              reviewVenderSummaryData={reviewVenderSummaryData}
              postRatingHandler={postRatingHandler}
              setWriteReviewPopup={setWriteReviewPopup}
              writeReviewPopup={writeReviewPopup}
            />
          </div>

          {/* Address Section End */}

          <section
            aria-labelledby="products-heading"
            className="py-4 border-2 border-gray-200 shadow-lg mt-4 rounded-lg"
          >
            {/* <!-- insta freatures --> */}
            <ul className="flex items-center justify-center md:justify-center space-x-12 uppercase tracking-widest font-semibold text-xs text-gray-600">
              {/* <!-- posts tab is active --> */}
              <li className="md:border-t md:border-gray-700 md:-mt-px md:text-gray-700">
                <div className="inline-block p-3">
                  <span className="hidden md:inline">posts</span>
                </div>
              </li>
            </ul>
            <div className="mx-8">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-12 ">
                {allPosts?.map((item, index) => (
                  <>
                    <div
                      className="bg-slate-100 border border-gray-300 hover:shadow-xl  p-2 rounded-lg relative"
                      key={index}
                    >
                      <Link to={"/products/" + item.productId}>
                        <div className="overflow-hidden">
                          {item?.mediaType === 1 ? (
                            <video
                              className="object-fit w-full h-48 transform rounded-t-md transition duration-500 hover:scale-95"
                              controls
                              src={item?.video}
                              // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                              // onMouseOver={(event) => event.target.play()}
                              // onMouseOut={(event) => event.target.pause()}
                              width="620"
                            ></video>
                          ) : (
                            <img
                              className="object-fit w-full h-48 transform rounded-t-md transition duration-500 hover:scale-95"
                              src={item?.imageUrlList[0]}
                              alt="productImage"
                            />
                          )}
                        </div>
                      </Link>

                      <div className="flex flex-col my-3 space-y-2">
                        <div className="group  relative">
                          <h2 className="text-xl font-semibold">
                            {item.name.slice(0, 20)}
                          </h2>
                          {/* <div className="tooltiptext bg-black text-white text-center rounded p-2 absolute z-10 left-1/2 -ml-12 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                            {item.name}
                          </div> */}
                        </div>

                        <div className="flex  mt-2.5 mb-3 justify-between">
                          {item.price !== "" && (
                            <h2 className="text-gray-600 flex">
                              ₹{item.price}
                            </h2>
                          )}
                          <div className="rating mr-2 flex h-6 items-center rounded-md bg-gray-500 px-2">
                            <AiFillStar className="mr-1 mt-[-1.5px] inline text-yellow-300" />
                            <span className="inline text-xs text-white">
                              {item?.rating}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default VenderDetailsPage;
