import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AddressField from "../common/AddressField";
import axios from "axios";
import OtpInputForm from "../components/OtpInput/otpInputForm";
import { AiFillCaretRight } from "react-icons/ai";
import { toast } from "react-toastify";
import Loading from "../common/Loading/Loading";
import { useDispatch } from "react-redux";
import { closeLoginModal } from "../utils/appSlice";
import { useNavigate } from "react-router-dom";
import { fetchNotifications } from "../utils/notificationSlice";
import { updateUserDataHandler } from "../utils/userProfileSlice";

const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z]+$/,
      "First Name must contain only alphabetic characters"
    )
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last Name must contain only alphabetic characters")
    .required("Last Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid mobile phone number format")
    .required("Mobile Number is required"),
  email: Yup.string().matches(emailRegex, "Invalid email format"),
  gender: Yup.string()
    .required("Gender is required")
    .oneOf(["male", "female"], "Please select gender"),
  age: Yup.number()
    .typeError("Age must be a number")
    .positive("Age must be a positive number")
    .integer("Age must be an integer")
    .min(18, "You must be at least 18 years old"),
  // .required("Age is required"),
  referCode: Yup.string(),
  lat: Yup.string().required("Latitude is required"),
  lng: Yup.string().required("Location is required"),
});

const SignupForm = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    gender: "select",
    age: "",
    referCode: "",
    lat: null,
    lng: null,
    address: "",
  };
  const [isType, setIsType] = useState("otp");
  const [otpData, setOtpData] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [signupInfo, setSignupInfo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOtpChange = (otp) => {
    setOtpData(otp);
  };

  const handleMobileNumberInput = (event, setFieldValue) => {
    const value = event.target.value;
    // Allow only numeric characters by removing non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedValue = numericValue.slice(0, 10);

    // Update the input field value with the limited numeric value
    event.target.value = limitedValue;

    // Set the Formik state with the limited numeric value
    setFieldValue("mobileNumber", limitedValue);
  };

  const handleAgeInput = (event, setFieldValue) => {
    const value = event.target.value;
    // Allow only numeric characters by removing non-numeric characters
    const numericValue = value.replace(/\D/g, "").slice(0, 3);

    // Update the input field value with the numeric value
    event.target.value = numericValue;

    // Set the Formik state with the numeric value
    setFieldValue("age", numericValue);
  };

  const handleNameInput = (event, fieldName, setFieldValue) => {
    const value = event.target.value;
    // Allow only alphabetic characters by removing non-alphabetic characters
    const alphabeticValue = value.replace(/[^A-Za-z]/g, "");

    // Update the input field value with the alphabetic value
    event.target.value = alphabeticValue;

    // Set the Formik state with the alphabetic value
    setFieldValue(fieldName, alphabeticValue);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const authHeaders = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        },
      };

      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobile: values.mobileNumber,
        email: values.email,
        gender: values.gender,
        age: values.age,
        userLat: values.lat,
        userLong: values.lng,
        // refer_code: values.referCode,
        address: values.address,
      };

      const otpQueryParam =
        isType === "otp"
          ? "otp="
          : `otp=${otpData}&refer_code=${values.referCode}`;

      const url = `${process.env.REACT_APP_API_URL}/api/users/signup?${otpQueryParam}`;

      const response = await axios.post(
        url,
        isType === "otp" ? body : signupInfo,
        authHeaders
      );
      const responseData = response.data;

      if (responseData.success && isType === "otp") {
        setShowOtp(true);
        setIsType("submit");
        toast.success(responseData.message);
        setLoading(false);
        setSignupInfo(body);
      }

      if (responseData.success && isType === "submit") {
        localStorage.setItem("userInfo", JSON.stringify(responseData.user));
        localStorage.setItem(
          "location",
          JSON.stringify({
            lat: responseData.user.userLat,
            lng: responseData.user.userLong,
          })
        );
        dispatch(updateUserDataHandler(responseData));
        dispatch(closeLoginModal());
        dispatch(fetchNotifications());
        navigate("/");
        toast.success(responseData.message);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <>
      {loading ? <Loading /> : null}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {showOtp ? (
              <div>
                <OtpInputForm
                  handleOtpChange={handleOtpChange}
                  mobileNumber={initialValues.mobileNumber}
                />
                <div className="flex justify-center text-center mt-5">
                  {/* <div className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
                    <span className="font-bold">Resend OTP</span>
                    <AiFillCaretRight className=" ml-1" />
                  </div> */}
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center mb-5">
                  <h5 className="text-xl font-medium text-gray-900">
                    SignUp to our platform
                  </h5>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      id="firstName"
                      name="firstName"
                      onChange={(event) =>
                        handleNameInput(event, "firstName", setFieldValue)
                      }
                    />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      id="lastName"
                      name="lastName"
                      onChange={(event) =>
                        handleNameInput(event, "lastName", setFieldValue)
                      }
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="mobileNumber"
                    >
                      Mobile Number
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      id="mobileNumber"
                      name="mobileNumber"
                      onChange={(event) =>
                        handleMobileNumberInput(event, setFieldValue)
                      }
                    />
                    <ErrorMessage
                      name="mobileNumber"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="email"
                      id="email"
                      name="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="gender"
                    >
                      Gender
                    </label>
                    <Field
                      as="select"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="gender"
                      name="gender"
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="age"
                    >
                      Age
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      id="age"
                      name="age"
                      onChange={(event) => handleAgeInput(event, setFieldValue)}
                    />
                    <ErrorMessage
                      name="age"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      htmlFor="referCode"
                    >
                      Refer Code
                    </label>
                    <Field
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      id="referCode"
                      name="referCode"
                    />
                    <ErrorMessage
                      name="referCode"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                </div>

                <div>
                  <AddressField
                    handleLocationSelect={(value) => {
                      setFieldValue("lat", value.lat);
                      setFieldValue("lng", value.lng);
                      setFieldValue("address", value.address);
                    }}
                  />
                  <ErrorMessage
                    name="lng"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </div>
              </>
            )}

            <div className="my-3 mx-auto flex w-fit flex-col space-y-3">
              {isType === "otp" && (
                <button
                  type="submit"
                  className=" inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Send OTP
                </button>
              )}
            </div>
            <div className="my-3 mx-auto flex w-fit flex-col space-y-3">
              {isType === "submit" && (
                <button
                  type="submit"
                  className=" inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  // onClick={handleSubmit}
                >
                  Sign Up
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
