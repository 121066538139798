import React, { useEffect, useRef, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import useOnScreen from "../../utils/hooks/useOnScreen";
import { postInsightHandler } from "../../utils/constants";

const AllProducts = ({ DataInfo }) => {
  return (
    <>
      <section className="antialiased  text-gray-900 font-sans px-4 sm:px-6 lg:px-8  md:mx-10 py-4">
        <div className=" px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="flex flex-wrap -mx-4">
            {DataInfo && DataInfo.map((item, index) => <Product item={item} />)}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllProducts;

const Product = ({ item }) => {
  const productRef = useRef(null);

  const onScreen = useOnScreen(productRef);
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    if (onScreen) {
      setIsViewed(true);
    }
  }, [onScreen]);

  useEffect(() => {
    if (isViewed) {
      postInsightHandler(item.productId);
    }
  }, [isViewed]);

  return (
    <div
      ref={productRef}
      className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 p-4"
      key={item.productId}
    >
      <Link to={"/products/" + item?.productId}>
        <div className="c-card block bg-slate-200 shadow-md hover:shadow-xl rounded-lg overflow-hidden">
          <div className="relative pb-48 overflow-hidden">
            {item?.mediaType === 1 ? (
              <video
                className="object-fit absolute inset-0 h-full w-full "
                controls
                src={item?.video}
                // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                // onMouseOver={(event) => event.target.play()}
                // onMouseOut={(event) => event.target.pause()}
                width="620"
              ></video>
            ) : (
              <img
                className="absolute inset-0 h-full w-full object-fit hover:scale-105 transition ease-in-out"
                src={item.imageUrlList[0]}
                alt="Product_Image"
              />
            )}
          </div>
          <div className="p-4">
            <div className="group  relative">
              <h2 className="text-xl font-semibold">
                {item.name.slice(0, 20)}
              </h2>
              <div className="tooltiptext bg-black text-white text-center rounded p-2 absolute z-10 -bottom-3 left-1/2 -ml-12 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                {item.name}
              </div>
            </div>
            {/* <p className="text-sm">{item.description}</p> */}
            <div className="mt-3 flex items-center">
              {item.price !== "" && (
                <>
                  <span className="text-sm font-semibold">₹</span>
                  &nbsp;
                  <span className="text-sm font-semibold">{item.price}</span>
                  &nbsp;
                  {/* <span className="text-sm font-semibold">
                   only
                 </span> */}
                </>
              )}
            </div>
          </div>

          <div className="p-4 flex items-center text-sm text-gray-600 border-t">
            {Array.from({ length: 5 }).map((_, i) => (
              <span
                key={i}
                className={`text-2xl ${
                  i < item.rating ? "text-yellow-500" : "text-gray-300"
                }`}
              >
                <AiFillStar className="h-4 w-4 fill-current" />
              </span>
            ))}
            <span className="ml-2">{item.totalRatingCount} Ratings</span>
          </div>
        </div>
      </Link>
    </div>
  );
};
