import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isMenuOpen: false,
    isLogoutOpen: false,
    isLoginOpen: false,
    isNotificationOpen: false,
  },
  reducers: {
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
    logoutModal: (state) => {
      state.isLogoutOpen = !state.isLogoutOpen;
    },
    closeLogoutModal: (state) => {
      state.isLogoutOpen = false;
    },
    loginModal: (state) => {
      state.isLoginOpen = !state.isLoginOpen;
    },
    closeLoginModal: (state) => {
      state.isLoginOpen = false;
    },
    notificationModal: (state) => {
      state.isNotificationOpen = !state.isNotificationOpen;
    },
    closeNotificationModal: (state) => {
      state.isNotificationOpen = false;
    },
  },
});

export const {
  toggleMenu,
  closeMenu,
  logoutModal,
  closeLogoutModal,
  loginModal,
  closeLoginModal,
  notificationModal,
  closeNotificationModal,
} = appSlice.actions;
export default appSlice.reducer;
