import { configureStore } from "@reduxjs/toolkit";
import appSlice from "../appSlice";
import userProfileSlice from "../userProfileSlice";
import notificationSlice from "../notificationSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    userProfile: userProfileSlice, // Update the slice name
    notifications: notificationSlice,
  },
});

export default store;
