import React, { useRef, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";
import copy from "clipboard-copy";

const CopyToClipboardButton = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  function copyToClipboard() {   
      copy(text);
      setIsCopied(true);
    
    // Reset the "Copied" message after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }

  const truncatedString = text?.length > 25 ? `${text.substring(0, 25)}...` : text;

  return (
    <div
      onClick={copyToClipboard}
      className="mb-5 flex min-w-full cursor-pointer items-center justify-evenly rounded-lg border border-slate-300 px-5 shadow-md hover:border-indigo-300"
    >
      {!isCopied ? <AiOutlineLink size={20} color="#a3a3a3" /> : ""}
      <span className="inline-flex w-full justify-center  truncate px-4  py-2 sm:mt-0 sm:w-auto">
        {!isCopied ? truncatedString : "Copied to clipboard!"}
      </span>

      {!isCopied ? <FaCopy size={18} color="#a3a3a3" /> : ""}
    </div>
  );
};

export default CopyToClipboardButton;
