import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useScrollSearch() {
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 1);
    const scrollThreshold = 400;

    const handleScroll = () => {
      if (location.pathname === "/" && window.scrollY > scrollThreshold) {
        setShowSearch(true);
      } else if (location.pathname === "/lists") {
        setShowSearch(true);
      } else {
        setShowSearch(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return showSearch;
}

export default useScrollSearch;
