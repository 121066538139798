import { useEffect, useState } from "react";
import axios from "axios";

const useVender = (venId) => {
  const [venderData, setVenderData] = useState(null);

  useEffect(() => {
    fetchVenderInfo();
  }, [venId]);

  async function fetchVenderInfo() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/vendor-profile?vendorId=${venId}`
      );
      const responseData = response.data;
      setVenderData(responseData.data);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  return venderData;
};

export default useVender;
