import React, { useState } from "react";
import AllProducts from "../../common/AllProducts/allProducts";
import Footer from "../../layouts/Footer/Footer";
// import Paginate from "./pagination";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../common/Loading/Loading";
import axios from "axios";

const SearchResults = () => {
  const [searchProducts, setSearchProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const item = searchParams.get("item");
  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab === "Popular") {
      getPopularPosts();
    }
  }, []);

  const getPopularPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/popular?latitude=${lat}&longitude=${lng}&radius=10`
      );

      const responseData = response.data;
      setSearchProducts(responseData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === "Recommended") {
      getRecommendedPosts();
    }
  }, []);

  const getRecommendedPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/recommended?latitude=${lat}&longitude=${lng}&radius=20`
      );

      const responseData = response.data;
      setSearchProducts(responseData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === "Search-NearBy") {
      getFetchSearchResult();
    }
  }, []);

  const getFetchSearchResult = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/search?user_lat=${lat}&user_long=${lng}&radius=20&keyword=${item}`
      );

      const responseData = response.data;
      setSearchProducts(responseData.product);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab === "Product" || tab === "Service" || tab === "What's New") {
      getProductByCategory();
    }
  }, []);

  const getProductByCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/product-by-cat?latitude=${lat}&longitude=${lng}&key=${tab}&radius=10`
      );

      const responseData = response.data;
      setSearchProducts(responseData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Outlet />
      <div>
        <div className="">
          <main className="mx-auto ">
            <div className="bg-gray-300 px-6 py-6">
              <div className="flex items-baseline justify-between">
                <h2 class="text-2xl font-bold tracking-tight text-gray-900">
                  {tab} Posts
                </h2>
              </div>
            </div>

            <section
              aria-labelledby="products-heading"
              className="pb-9 border bg-slate-100 rounded-lg"
            >
              {searchProducts === null ||
                (searchProducts?.length === 0 && (
                  <div className="flex justify-center items-center my-32">
                    <h2 class="text-2xl font-bold tracking-tight text-gray-900">
                      There are no Information Availabel, Please contact to
                      MakeAssure!
                    </h2>
                  </div>
                ))}
              <AllProducts DataInfo={searchProducts} />

              {/* {PopularData && PopularData.total > 0 ? (
            <Paginate totalPage={searchlist.response.last_page} />
          ) : null} */}
              {/* <Paginate /> */}
            </section>
          </main>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SearchResults;
