import React, { useState, useRef } from "react";

const OtpInputForm = ({
  handleOtpChange,
  mobileNumber,
  handleSignInSubmit,
  type,
}) => {
  const [otp, setOtp] = useState(""); // Initialize with an empty string
  const inputRefs = [useRef(), useRef(), useRef(), useRef()]; // Create refs for each input

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      // Only allow digits
      const newOtp = otp.slice(0, index) + value + otp.slice(index + 1);
      setOtp(newOtp);
      handleOtpChange(newOtp);

      if (value !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus(); // Move focus to the next input
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = otp.slice(0, index) + otp.slice(index + 1);
      setOtp(newOtp);

      if (index > 0) {
        inputRefs[index - 1].current.focus(); // Move focus to the previous input
      } else {
        inputRefs[index].current.focus(); // Keep focus on the current input
      }

      event.preventDefault(); // Prevent the default Backspace behavior (going back in browser)
    }

    if (otp.length === 4 && event.key === "Enter" && type === "LOGIN") {
      handleSignInSubmit();
    }
  };

  return (
    <div className="mt-3 px-3">
      <div className="max-w-sm mx-auto md:max-w-lg">
        <div className="rounded text-center">
          <div className=" flex flex-col justify-center">
            <h1 className="text-2xl font-bold">OTP Verification</h1>
            <div className="flex flex-col mt-4">
              <span>Enter the OTP you received at</span>
              <span className="font-bold">
                +91 {"******" + mobileNumber?.slice(-4)}
              </span>
            </div>
          </div>

          <div
            id="otp"
            className="flex flex-row justify-center text-center px-2 mt-5"
          >
            {inputRefs.map((ref, index) => (
              <input
                key={index}
                ref={ref}
                className="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                maxLength="1"
                value={otp[index] || ""}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpInputForm;
