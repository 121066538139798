import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeNotificationModal } from "../../utils/appSlice";
import {
  fetchNotifications,
  fetchNotificationsCount,
} from "../../utils/notificationSlice";

const Notification = () => {
  const userToken = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNotificationOpen = useSelector(
    (store) => store.app.isNotificationOpen
  );
  const [notificationStatus, setNotificationStatus] = useState();
  const notification = useSelector((state) => state.notifications.data);

  function calculateTimeAgo(notificationDate) {
    const currentDate = new Date();
    const notificationDateObject = new Date(notificationDate);
    const timeDiffInMillis = currentDate - notificationDateObject;

    // Convert milliseconds to seconds
    const timeDiffInSeconds = Math.floor(timeDiffInMillis / 1000);

    let timeAgo = "";

    if (timeDiffInSeconds < 60) {
      timeAgo = `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      timeAgo = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      timeAgo = `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else {
      const days = Math.floor(timeDiffInSeconds / 86400);
      timeAgo = `${days} ${days === 1 ? "day" : "days"} ago`;
    }

    return timeAgo;
  }

  useEffect(() => {
    dispatch(fetchNotifications());
    dispatch(fetchNotificationsCount());
  }, [notificationStatus, dispatch, isNotificationOpen]);

  const handleMarkAsRead = async (item) => {
    const authHeaders = {
      headers: {
        Authorization: userDetails.token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // "Content-Type": "application/json",
      },
    };

    const formData = new FormData();

    formData.append("notification_ids", item?.id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/mark-as-read`,
        formData,
        authHeaders
      );

      const { data } = response.data;
      setNotificationStatus(response.data);
      if (item.notification_type === "POST") {
        navigate(`/products/${item.notification_go_to_id}`);
      } else if (item.notification_type === "PAGE") {
        navigate(`/vender-details/${item.notification_go_to_id}`);
      }
      dispatch(closeNotificationModal());
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  };

  // Early Return pattern
  if (!isNotificationOpen) return null;

  return (
    <>
      <div
        className="relative z-50 h-fit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <section className="flex flex-col items-center justify-center w-fit text-gray-800 p-10">
              <div className="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="flex justify-between py-4 px-5 bg-slate-200">
                  <div className="flex items-center">
                    <h2 className="text-xl leading-snug font-bold">
                      Notifications
                    </h2>
                  </div>
                  <div
                    className="cursor-pointer text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                    onClick={() => dispatch(closeNotificationModal())}
                  >
                    <RxCross2 className="w-5 h-5" />
                  </div>
                </div>

                <div className=" mx-8 my-10 h-96 overflow-y-scroll">
                  {notification?.length === 0 && (
                    <div className="flex justify-center items-center my-32">
                      <h2 class="text-2xl font-bold tracking-tight text-gray-900">
                        You have no notification!
                      </h2>
                    </div>
                  )}
                  {notification?.map((item, index) => (
                    <>
                      <div
                        className="mt-2 px-4 py-2 bg-slate-200 rounded-lg shadow w-full cursor-pointer"
                        key={index}
                        onClick={() => handleMarkAsRead(item)}
                      >
                        <div class="w-full p-3 mt-4 bg-white rounded flex">
                          <p className="text-xs text-gray-500">
                            {item?.notification_status === "UNREAD" ? (
                              <div className="bg-red-500 rounded-full p-1"></div>
                            ) : (
                              <div className="bg-green-600 rounded-full p-1"></div>
                            )}
                          </p>
                          <div
                            tabindex="0"
                            aria-label="post icon"
                            role="img"
                            class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.30325 12.6667L1.33325 15V2.66667C1.33325 2.48986 1.40349 2.32029 1.52851 2.19526C1.65354 2.07024 1.82311 2 1.99992 2H13.9999C14.1767 2 14.3463 2.07024 14.4713 2.19526C14.5963 2.32029 14.6666 2.48986 14.6666 2.66667V12C14.6666 12.1768 14.5963 12.3464 14.4713 12.4714C14.3463 12.5964 14.1767 12.6667 13.9999 12.6667H4.30325ZM5.33325 6.66667V8H10.6666V6.66667H5.33325Z"
                                fill="#4338CA"
                              />
                            </svg>
                          </div>
                          <div class="pl-3">
                            <p
                              tabindex="0"
                              class="focus:outline-none text-sm leading-none"
                            >
                              <span class="text-indigo-700">
                                {item.notification_message}
                              </span>
                            </p>
                            <p
                              tabindex="0"
                              class="focus:outline-none text-xs leading-3 pt-1 text-gray-500"
                            >
                              {calculateTimeAgo(item?.notification_date)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
