import React from "react";

const ServiceAndContentPolicy = () => {
  return (
    <>
      <div className="bg-gray-100 py-12 px-4">
        <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-semibold mb-6 underline">
            Description of Service &amp; Content Policy
          </h1>
          <p className="mb-4">
            A. MakeAssure is the next generation of online classifieds. We act
            as a venue to allow our users who comply with these Terms to offer,
            sell, and buy products and services listed on the Platform. Although
            you may be able to conduct payment and other transactions through
            the Platform, using third-party vendors such MakeAssure is not in
            any way involved in such transactions. As a result, and as discussed
            in more detail in these Terms, you hereby acknowledge and agree that
            MakeAssure is not a party to such transactions, has no control over
            any element of such transactions, and shall have no liability to any
            party in connection with such transactions. You use the Service and
            the Platform at your own risk.
          </p>
          <p className="mb-4">
            B. You understand that MakeAssure does not control, and is not
            responsible for ads, directory information, business
            listings/information, messages between users, including without
            limitation e-mails sent from outside MakeAssure ’s domain or other
            means of electronic communication, whether through the Platform or
            another Third Party Website (defined below) or offerings, comments,
            user postings, files, images, photos, video, sounds, business
            listings/information and directory information or any other material
            made available through the Platform and the Service
            (&quot;Content&quot;), and that by using the Platform and the
            Service, you may be exposed to Content that is offensive, indecent,
            inaccurate, misleading, or otherwise objectionable. You acknowledge
            and agree that you are responsible for and must evaluate, and bear
            all risks associated with, the use of any Content, that you may not
            rely on said Content, and that under no circumstances will
            MakeAssure be liable in any way for the Content or for any loss or
            damage of any kind incurred as a result of the browsing, using or
            reading any Content listed, emailed or otherwise made available via
            the Service. You acknowledge and agree that MakeAssure can’t and
            does not pre-screen or approve any Content, but that MakeAssure has
            the right, in its sole and absolute discretion, to refuse, delete or
            move any Content that is or may be available through the Service,
            for violating these Terms and such violation being brought to
            MakeAssure ’s knowledge or for any other reason or no reason at all.
            Furthermore, the Platform and Content available through the Platform
            may contain links to other third party websites (&quot;Third Party
            Websites&quot;), which are completely unrelated to MakeAssure . If
            you link to Third Party Websites, you may be subject to those Third
            Party Websites’ terms and conditions and other policies. MakeAssure
            makes no representation or guarantee as to the accuracy or
            authenticity of the information contained in any such Third Party
            Website, and your linking to any other websites is completely at
            your own risk and MakeAssure disclaims all liability there to.
          </p>
          <p className="mb-4">
            You acknowledge and agree that you are solely responsible for your
            own Content posted on, transmitted through, or linked from the
            Service and the consequences of posting, transmitting, linking or
            publishing it. More specifically, you are solely responsible for all
            Content that you upload, email or otherwise make available via the
            Service. In connection with such Content posted on, transmitted
            through, or linked from the Service by you, you affirm, acknowledge,
            represent, warrant and covenant that:
          </p>

          <p className="mb-4 ml-8">
            I. You own or have and shall continue to, for such time the Content
            is available on the Platform, have the necessary licenses, rights,
            consents, and permissions to use such Content on the Service and
            Platform (including without limitation all patent, trademark, trade
            secret, copyright or other proprietary rights in and to any and all
            such Content) and authorize MakeAssure to use such Content to enable
            inclusion and use of the Content in the manner contemplated by the
            Service, the Platform and these Terms.
          </p>

          <p className="mb-4 ml-8">
            II. You have the written consent, release, and/or permission of each
            and every identifiable individual person or business in the Content
            to use the name or likeness of each and every such identifiable
            individual person or business to enable inclusion and use of the
            Content in the manner contemplated by the Service, the Platform and
            these Terms. For clarity, you retain all of your ownership rights in
            your Content; however, by submitting any Content on the Platform,
            you hereby grant to MakeAssure an irrevocable, non-cancellable,
            perpetual, worldwide, non-exclusive, royalty-free, sub-licensable,
            transferable license to use, reproduce, distribute, prepare
            derivative works of, display, and perform the Content in connection
            with the Platform and MakeAssure &#39;s (and its successors&#39;)
            business, including without limitation for the purpose of promoting
            and redistributing part or all of the Platform and Content therein
            (and derivative works thereof) in any media formats and through any
            media channels now or hereafter known. These rights are required by
            MakeAssure in order to host and display your Content. Furthermore,
            by you posting Content to any public area of the Service, you agree
            to and do hereby grant to MakeAssure all rights necessary to
            prohibit or allow any subsequent aggregation, display, copying,
            duplication, reproduction, or exploitation of the Content on the
            Service or Platform by any party for any purpose. As a part of the
            Service, MakeAssure may offer the facility of automatically
            capturing of the &quot;Description&quot; of your ad based on the
            images uploaded by you. MakeAssure makes no warranty the veracity or
            the accuracy of the generated Description. The Description may be
            edited by you at any time while your ad is live. You also hereby
            grant each user of the Platform
          </p>
          <p className="mb-4 ml-14">
            A. A non-exclusive license to access your Content through the
            Platform, and
          </p>
          <p className="mb-4 ml-14">
            B. The right to contact you with regard to the Content posted by you
            through private chat or any other means.
          </p>
          <p className="mb-4 ml-8">
            The foregoing license to each user granted by you terminates once
            you or MakeAssure remove or delete such Content from the Platform.
            Further, you grant MakeAssure the right to make available your
            Content to any third party in connection with the transaction
            contemplated in your classified advertisement.
          </p>
          <p className="mb-4">
            D. MakeAssure does not endorse any Content or any opinion,
            statement, recommendation, or advice expressed therein, and
            MakeAssure expressly disclaims any and all liability in connection
            with User Content. MakeAssure does not permit copyright infringing
            activities and infringement of intellectual property rights on the
            Platform, and MakeAssure may, at its sole discretion, remove any
            infringing Content if properly notified in accordance with
            applicable law that such Content infringes on another&#39;s
            intellectual property rights. MakeAssure reserves the right to
            remove any Content without prior notice. MakeAssure may also
            terminate a user&#39;s access to the Platform if they are determined
            to be a repeat infringer or found to be indulging in any act
            contrary to these Terms.
          </p>
          <p className="mb-4">
            [Note: A repeat infringer is a user who has been notified of
            infringing activity more than twice and/or has had a user submission
            removed from the Platform more than twice.]
          </p>
          <p className="mb-4">
            Further, at its sole discretion, MakeAssure reserves the right to
            decide whether any Content is appropriate and complies with these
            Terms.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">CONDUCT:</h1>
          <p className="mb-4">
            You agree not to post, email, host, display, upload, modify,
            publish, transmit, update or share any information on the Site, or
            otherwise make available Content:
          </p>
          <p className="mb-4">i. That violates any law or regulation.</p>
          <p className="mb-4">
            ii. That is copyrighted or patented, protected by trade secret or
            trademark, or otherwise subject to third party proprietary rights,
            including privacy and publicity rights, unless you are the owner of
            such rights or have permission or a license from their rightful
            owner to post the material and to grant MakeAssure all of the
            license rights granted herein.
          </p>
          <p className="mb-4">
            iii. That infringes any of the foregoing intellectual property
            rights of any party, or is Content that you do not have a right to
            make available under any law, regulation, contractual or fiduciary
            relationship(s).
          </p>
          <p className="mb-4">
            iv. That is harmful, abusive, unlawful, threatening, harassing,
            blasphemous, defamatory, obscene, pornographic, paedophilic,
            libelous, invasive of another&#39;s privacy or other rights,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or illegal gambling or
            harms or could harm minors in any way or otherwise unlawful in any
            manner whatsoever.
          </p>
          <p className="mb-4">
            v. That harasses, degrades, intimidates or is hateful towards any
            individual or group of individuals on the basis of religion, gender,
            sexual orientation, race, ethnicity, age, or disability.
          </p>
          <p className="mb-4">
            vi. That violates any (local) equal employment laws, including but
            not limited to those prohibiting the stating, in any advertisement
            for employment, a preference or requirement based on race, colour,
            religion, sex, national origin, age, or disability of the applicant.
          </p>
          <p className="mb-4">
            vii. That threatens the unity, integrity, defence, security or
            sovereignty of India, friendly relations with foreign states, or
            public order or causes incitement to the commission of any
            cognizable offense or prevents investigation of any offense or is
            insulting any other nation.
          </p>
          <p className="mb-4">
            viii. That includes personal or identifying information about
            another person without that person&#39;s explicit consent.
          </p>
          <p className="mb-4">
            ix. That impersonates any person or entity, including, but not
            limited to, a MakeAssure employee, or falsely states or otherwise
            misrepresents an affiliation with a person or entity.
          </p>
          <p className="mb-4">
            x. Deceives or misleads the addressee about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature.
          </p>
          <p className="mb-4">
            xi. That is false, deceptive, misleading, deceitful, misinformative,
            or constitutes &quot;bait and switch&quot; offer.
          </p>
          <p className="mb-4">
            xii. That constitutes or contains &quot;pyramid schemes,&quot;
            &quot;affiliate marketing,&quot; &quot;link referral code,&quot;
            &quot;junk mail,&quot; &quot;spam,&quot; &quot;chain letters,&quot;
            or unsolicited advertisements of a commercial nature.
          </p>
          <p className="mb-4">
            xiii. That constitutes or contains any form of advertising or
            solicitation if
            <p className="mb-4 ml-8">
              (1) posted in areas or categories of the Platform which are not
              designated for such purposes; or
            </p>
            <p className="mb-4 ml-8">
              (2) e-mailed to MakeAssure users who have requested not to be
              contacted about other services, products or commercial interests.
            </p>
          </p>
          <p className="mb-4">
            xiv. That includes links to commercial services or Third Party
            Websites, except as specifically allowed by MakeAssure.
          </p>
          <p className="mb-4">
            xv. That advertises any illegal services or the sale of any items
            the sale of which is prohibited or restricted by applicable law,
            including without limitation items the sale of which is prohibited
            or regulated by applicable law.
          </p>
          <p className="mb-4">
            xvi. That contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment or any other computer resource.
          </p>
          <p className="mb-4">
            xvii. That disrupts the normal flow of dialogue with an excessive
            number of messages (flooding attack) to the Service, or that
            otherwise negatively affects other users&#39; ability to use the
            Service. or
          </p>
          <p className="mb-4">
            xviii. That employs misleading email addresses, or forged headers or
            otherwise manipulated identifiers in order to disguise the origin of
            Content transmitted through the Service.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            PAID SERVICES
          </h1>
          <p className="mb-4">
            We may charge a fee to post content in or utilize some areas of the
            Service (“Paid Services”). MakeAssure has authorised MakeAssure
            Services Private Limited, an Indian company (CIN:
            U74999OR2021PTC035288) having its registered office PLOT NO-
            6162/6555/8713,Khata No-932/498
            ,At-Kumanda,Ps/Dist-Angul,Orissa,India, 759143 (“MakeAssure India”
            or “we” or “us”) to sell such Paid Services to its users. By posting
            Paid Services on the Website you enter into an agreement with
            MakeAssure, the terms of which are set out herein. Provisions of the
            Terms relating to the Content shall also be applicable to Paid
            Services. Further, you continue to be bound by the Terms in
            connection with your use of the Website and the Services. Currently,
            we offer the following kinds of features for Paid Services. Only
            some of these features may be made available to you by us, depending
            on your usage, category, geographical location and medium of
            purchase. The terms of Paid Services and the relationship between
            you and MakeAssure shall be governed by the laws of India. Any claim
            you may have against MakeAssure must be submitted to the exclusive
            jurisdiction the courts of Cuttack. We reserve the right to modify
            the price at which these features are made available to you:
          </p>
          <ul className="list-disc pl-6">
            <li>
              <strong>Banner Post:</strong> This feature Pumps your post to the
              top of the listing space in the locality within specific category
              in which the post has been posted. Your post will remain in that
              spot until as per your plan. This feature has been provided to
              enable users to draw attention to their live post and increase the
              likelihood of receiving responses from potential buyers. We do not
              guarantee an increase of a specified quantum in the responses
              received by you and any representation on the Website in this
              regard is merely indicative.
            </li>
            <li>
              <strong>Promote Post:</strong> This feature Pumps your post to Ads
              section of the listing space in the locality within specific
              category in which the post has been posted. Your post will remain
              in that spot until as per your plan. • This feature has been
              provided to enable users to draw attention to their live post and
              increase the likelihood of receiving responses from potential
              buyers. We do not guarantee an increase of a specified quantum in
              the responses received by you and any representation on the
              Website in this regard is merely indicative.
            </li>
          </ul>

          <h1 className="text-2xl font-semibold mb-6 underline">
            Refunds/Cancellation
          </h1>
          <p className="mb-4">
            If you choose to delete or deactivate any Paid Services prior to the
            period for which it is active, we will not be liable to refund you
            for any unutilized time. In the event you are not able to avail the
            Paid Services on the Website on account of any technical reasons, on
            receipt of payment confirmation, equivalent units of the paid
            feature against the ad units you were not able to post shall be
            credited to your account.
          </p>
          <p className="mb-4">
            The above refund policy shall not be applicable in the event any
            content in your post is found to be violating the Terms or there has
            been any abuse reported in relation to your post or your
            advertisement is deleted or not re-posted on the Website on account
            of any technical reasons due to any subsequent editing done by you.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            Payment for Paid Services
          </h1>
          <p className="mb-4">
            Upon selecting any feature of Paid Services, you may be redirected
            to a third-party payment processing site such as PayTm offering
            payment through various channels such as net banking, debit card and
            credit card (“Payment Gateway”). MakeAssure may offer you a right to
            pay through cash or other channels such as cash collection. You
            agree that Paid Services will be made available to you only upon
            receipt of payment confirmation from the Payment Gateway or
            collection agencies. Upon making payment to us, through any medium
            whatsoever, you warrant to us that you are entitled to use the
            medium or otherwise make the payment to us under applicable law.
          </p>
          <p className="mb-4">
            You have the ability to provide us your Goods and Services Tax
            Identification Number (GSTIN) on the platform. Your GSTIN details
            are mandatory for passing on the credit of GST charged. In case you
            fail to provide us with your GSTIN details, we will assume that you
            are not registered with the GST authorities, without independently
            verifying your registration status.
          </p>
          <p className="mb-4">
            Unless otherwise mentioned on the Website, prices indicated are
            excluding of applicable taxes.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            ACCESS TO THE SERVICE
          </h1>
          <p className="mb-4">
            MakeAssure grants you a limited, revocable, non-exclusive license to
            access and use the Service for personal/Professional use. This
            license granted herein does not include any of the following:
          </p>
          <p className="mb-4">
            (a) access to or use of the Service by Posting Agents; or
          </p>
          <p className="mb-4">
            (b) any collection, aggregation, copying, duplication, display or
            derivative use of the Service nor any use of data mining, robots,
            spiders, or similar data gathering and extraction tools for any
            purpose unless expressly permitted by MakeAssure or as otherwise set
            forth in these Terms.
          </p>
          <p className="mb-4">
            Notwithstanding the foregoing, general purpose internet search
            engines and non- commercial public archives that gather information
            for the sole purpose of displaying hyperlinks to the Service,
            provided they each do so from a stable IP address or range of IP
            addresses using an easily identifiable agent and comply with our
            robots.txt file, may engage in the activities set forth in (b). For
            purposes of this exception, a &quot;general purpose internet search
            engine&quot; does not include a website or search engine or other
            services that specializes in classified listings including any
            subset of classifieds listings such services, or
            personals/Professional, or which otherwise provides classified ad
            listing services. The license set forth in this Section permits you
            to display on your website, or create a hyperlink thereto,
            individual postings on the Service so long as such use is for
            non-commercial and/or news reporting purposes only (e.g., for use in
            personal blogs or other personal online media). If the total number
            of such postings displayed on or linked to your website exceeds
            limits set by MakeAssure, your use will be considered to be in
            violation of these Terms, unless MakeAssure expressly grants you
            permission otherwise. You are also permitted to create a hyperlink
            to the home page of the Platform so long as the link does not
            portray MakeAssure, its employees, affiliates or agents in a false,
            confusing, misleading, derogatory, or otherwise offensive matter.
            Use of the Service beyond the scope of authorized access as set
            forth in these Terms immediately terminates any permission or
            license granted herein. In order to collect, aggregate, copy,
            duplicate, display or make derivative use of the Service or any
            Content made available via the Service for other purposes (including
            commercial purposes) not stated herein, you must first obtain a
            license from MakeAssure.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            NO SPAM POLICY
          </h1>
          <p className="mb-4">
            You understand and agree that sending unsolicited email
            advertisements or other unsolicited communications to MakeAssure
            email addresses or through MakeAssure computer systems are expressly
            prohibited by these Terms. You acknowledge and agree that from time
            to time MakeAssure may monitor email usage using human monitors or
            automated software to flag certain words associated with spam or
            scams in emails that are sent between one user to another in the
            MakeAssure email system. Any communication between yourself and any
            other user utilizing the communication features available on the
            Service and the Platform may be used only in accordance with the
            Terms. Any unauthorized use of MakeAssure computer systems is a
            violation of these Terms and certain applicable laws. Such
            violations may subject the sender and his or her agents to civil and
            criminal penalties.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            DEALINGS WITH ORGANIZATIONS AND INDIVIDUALS
          </h1>
          <p className="mb-4">
            You acknowledge and agree that MakeAssure shall not be liable for
            your interactions with any organizations and/or individuals on the
            Platform or through the Service. This includes, but is not limited
            to, payment and delivery of goods and services, and any other terms,
            conditions, warranties or representations associated with any
            interaction you may have with other organizations and/or
            individuals. These dealings are solely between you and such
            organizations and/or individuals. You agree and acknowledge that
            MakeAssure shall not be responsible or liable for any loss or damage
            of any sort incurred as the result of any such dealings or
            interactions. If there is a dispute between participants on the
            Platform, or between users and any third party, you understand and
            agree that MakeAssure is under no obligation to become involved in
            such dispute. In the event that you have a dispute with one or more
            other users, you hereby release MakeAssure, its officers, employees,
            agents and successors from any and all claims, demands and damages
            (actual and consequential) of every kind or nature, known or
            unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way related to such disputes and/or our
            Service.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            LIMITATION AND TERMINATION OF SERVICE
          </h1>
          <p className="mb-4">
            You acknowledge and agree that MakeAssure may establish limits from
            time to time concerning use of the Service, including among others,
            the maximum number of days that Content will be maintained or
            retained by the Service, the maximum number and size of postings,
            e-mail messages, or other Content that may be transmitted or stored
            by the Service, and the frequency and the manner in which you may
            access the Service or the Platform. You acknowledge that your
            account is identified and linked through your mobile number,
            Facebook account or email address through which you have registered.
            In the event you have more than one account linked through your
            mobile number, Facebook account or email address, MakeAssure
            reserves the right to remove or restrict the usage of such duplicate
            accounts. You acknowledge and agree that MakeAssure has no
            responsibility or liability for the deletion or failure to store any
            Content maintained or transmitted by the Platform or the Service.
            You acknowledge and agree that MakeAssure reserves the right at any
            time to modify, limit or discontinue the Service (or any part
            thereof) with or without notice and that MakeAssure shall not be
            liable to you or to any third party for any such modification,
            suspension or discontinuance of the Service. You acknowledge and
            agree that MakeAssure, in its sole and absolute discretion, has the
            right (but not the obligation) to delete or deactivate your account,
            block your e-mail or IP address, or otherwise terminate your access
            to or use of the Service (or any part thereof), immediately and
            without notice, and remove and discard any Content within the
            Service, for any reason or no reason at all, including, without
            limitation, if MakeAssure believes that you have violated these
            Terms. Further, you agree that MakeAssure shall not be liable to you
            or any third-party for any termination of your access to the
            Platform or the Service. Further, you agree not to attempt to use
            the Service after any such termination.
          </p>

          <h1 className="text-2xl font-semibold mb-6 underline">
            ABILITY TO ACCEPT TERMS OF SERVICE
          </h1>
          <p className="mb-4">
            This Platform is intended only for adults and that you are eligible
            to contract as per applicable laws. If you are using/accessing this
            Platform as a representative of any person/entity, you acknowledge
            that you are legally authorized to represent that person/entity.
            Minors, i.e. users of under 18 years of age, are only allowed to
            access the Platform and use the Service, in the event of approval of
            their legal representatives or in the event that it concerns an act
            or a transaction that is usual and acceptable standard in civil life
            and practice. You affirm that you are either at least 18 years of
            age, or an emancipated minor, or possess legal parental or guardian
            consent, and are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations, and
            warranties set forth in these Terms, and to abide by and comply with
            these Terms. In any case, you affirm that you are over the age of
            13, as the Platform is not intended for children under 13.
          </p>

          <p className="mb-4 text-red-600">
            NOTICE TO CHILDREN UNDER THE AGE OF 13 AND THEIR PARENTS OR
            GUARDIANS.
          </p>
          <p className="mb-4 text-red-600">
            If you are under the age of 13, YOU MUST NOT USE THIS PLATFORM.
            Please do not send us your personal information, including your
            email addresses, name, and/or contact information. If you want to
            contact us, you may only do so through your parent or legal
            guardian.
          </p>
        </div>
      </div>
    </>
  );
};

export default ServiceAndContentPolicy;
