// src/features/userProfileSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axioss from "../config/axios";
import axios from "axios";

export const fetchUserDetails = createAsyncThunk(
  "user/fetchUserDetails",
  async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userInfo"));
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        },
      };

      const response = await axioss.get(
        `${process.env.REACT_APP_API_URL}/api/users/user-profile?userId=${userDetails.customId}`,
        auth
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    userData: null,
    userStatus: "idle",
    userError: null,
    location: null,
  },
  reducers: {
    userLocation(state, { payload }) {
      state.location = payload;
    },
    updateUserDataHandler(state, { payload }) {
      state.userData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.userStatus = "loading";
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userStatus = "succeeded";
        state.userData = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.userStatus = "failed";
        state.userError = action.error.message;
      });
  },
});

export const { userLocation, updateUserDataHandler } = userProfileSlice.actions;
export default userProfileSlice.reducer;
