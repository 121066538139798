import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

export default function WriteReview({
  setWriteReviewPopup,
  postRatingHandler,
}) {
  const [stars, setStars] = useState(0);

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 mt-9 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Rate Us
                    </h3>
                    <div className="mt-2">
                      <div className="my-0 flex items-center">
                        <h6 className="w-1/3 text-sm">Rating</h6>
                        <ReactStars
                          required
                          count={5}
                          // isHalf={true}
                          size={35}
                          activeColor="#ffd700"
                          onChange={(value) => setStars(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  className="inline-flex w-full justify-center rounded-md border border-transparent  bg-btn-color px-4 py-2 text-base font-medium text-white shadow-sm bg-blue-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    postRatingHandler(stars);
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => setWriteReviewPopup(false)}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
