import axios from "axios";

const instance = axios.create();

instance.interceptors.response.use(
  (resp) => resp,
  async (err) => {
    if (
      err.response.status === 400 &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      // Remove the "userInfo" item from local storage first
      localStorage.removeItem("userInfo");
      
      // Redirect the user to the homepage
      window.location.href = "/";
    }

    return Promise.reject(err);
  }
);

export default instance;
