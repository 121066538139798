import React from "react";
import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/io5";
import {
  BsInstagram,
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsGithub,
  BsYoutube,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import LogoTwo from "../../../src/assets/images/logo2.png";

const Footer = () => {
  return (
    <div>
      <footer className="relative bg-slate-100 border-t-2 pt-8 pb-6">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-left lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <Link className="mb-2 inline-block max-w-[160px]">
                <img src={LogoTwo} alt="logo" className="max-w-full" />
              </Link>
              {/* <h4 className="text-3xl fonat-semibold text-blue-700">
                Let's keep in touch!
              </h4>*/}
              <h5 className="text-lg mt-0 mb-2 ">
                We respond within 1-2 business days.
              </h5>
              <div className="mt-6 lg:mb-0 mb-6">
                <div className="flex  space-x-6">
                  <Link
                    to="https://www.facebook.com/MakeAssureServices"
                    target="_blank"
                    className="text-blue-800 hover:text-gray-500"
                  >
                    <span className="sr-only">Facebook</span>
                    <BsFacebook className="w-6 h-6" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/makeassure/"
                    target="_blank"
                    className="text-pink-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Instagram</span>
                    <BsInstagram className="w-6 h-6" />
                  </Link>
                  <Link
                    to="https://twitter.com/MakeAssure"
                    target="_blank"
                    className="text-blue-600 hover:text-gray-500"
                  >
                    <span className="sr-only">Twitter</span>
                    <BsTwitter className="w-6 h-6" />
                  </Link>
                  <Link
                    to="https://www.youtube.com/@makeassure"
                    target="_blank"
                    className="text-red-600 hover:text-gray-500"
                  >
                    <span className="sr-only">Youtube</span>
                    <BsYoutube className="w-6 h-6" />
                  </Link>
                </div>
              </div>
              <div className="mt-6 mb-2 flex flex-row flex-wrap">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.app.makeassure&hl=en"
                  target="_blank"
                >
                  <div className="mr-5 mb-6 flex h-14 w-fit cursor-pointer items-center justify-around rounded-xl border-2 border-gray-300 bg-gray-100 p-4 hover:scale-105 mt-4 md:mt-0">
                    <IoLogoGooglePlaystore size={30} color="black" />

                    <span className="ml-2 ">
                      <h5 className="text-sm font-semibold">Get it on</h5>
                      <h6 className="text-base font-bold">Google Play</h6>
                    </span>
                  </div>
                </Link>
                {/* <Link
                  // href="https://apps.apple.com/sg/app/inteam-are-you-in/id1629299444"
                  target="_blank"
                >
                  <div className="mr-5 flex h-14 w-fit cursor-pointer items-center justify-around rounded-xl border-2 border-gray-300 bg-gray-100 p-4 hover:scale-105 mt-4 md:mt-0">
                    <IoLogoAppleAppstore size={30} color="black" />

                    <span className="ml-2">
                      <h5 className="text-sm font-semibold">
                        Available on the
                      </h5>
                      <h6 className="text-base font-bold">App Store</h6>
                    </span>
                  </div>
                </Link> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6 mt-5 md:mt-0">
                <div className="w-full lg:w-7/12 px-4 ml-auto">
                  <span className="block uppercase text-sm font-bold mb-5">
                    Helpful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className=" hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/about"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/terms-and-conditions"
                      >
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/refund-and-cancellation-policy"
                      >
                        Refund &amp; Cancellation Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/service-and-content-policy"
                      >
                        Service &amp; Content Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-blue-800 font-semibold block pb-2 text-sm"
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blue-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm font-semibold py-1">
                Copyright © <span id="get-current-year">2024</span>
                <Link
                  href="https://www.creative-tim.com/product/notus-js"
                  className=" hover:text-gray-800"
                  target="_blank"
                />{" "}
                MakeAssure,
                <Link
                  href="https://www.creative-tim.com?ref=njs-profile"
                  className=" hover:text-blue-800"
                >
                  Inc. All rights reserved.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
