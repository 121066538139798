import React from "react";
import { BsInstagram, BsLink45Deg, BsTwitter } from "react-icons/bs";
import { GrFacebook } from "react-icons/gr";
import { ImWhatsapp } from "react-icons/im";
import { MdCancel } from "react-icons/md";
import CopyToClipboardButton from "../../../components/CopyToClipBoardButton/CopyToClipboardButton";

const ShareDetailsModal = ({ setSharePopup, sharePageUrl }) => {
  const shareUrl = `https://makeassure.com/${sharePageUrl}`;
  return (
    <div>
      <div
        className="relative z-10 h-fit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="inputs m-1 max-w-full overflow-y-hidden rounded px-4">
                <div>
                  <h5 className="my-2 mb-4 text-xl font-medium text-gray-900">
                    Share with your Friends
                    <div
                      className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                      onClick={() => setSharePopup(false)}
                    >
                      <MdCancel size={25} />
                    </div>
                  </h5>
                </div>

                <hr />
                <h5 className="my-2 mb-4 text-md font-medium text-gray-600">
                  Select from the options below
                </h5>
                <div className="sharing-buttons flex justify-center gap-2">
                  <a
                    className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-3 px-3 rounded-full text-white border-blue-500 bg-blue-500 hover:bg-blue-700 hover:border-blue-700"
                    target="_blank"
                    title="Facebook"
                    rel="noopener"
                    href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    aria-label="Share on Facebook"
                  >
                    <GrFacebook className="w-6 h-6" />
                  </a>
                  <a
                    className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-3 px-3 rounded-full text-white border-blue-500 bg-blue-500 hover:bg-blue-700 hover:border-blue-700"
                    target="_blank"
                    rel="noopener"
                    title="Whatsapp"
                    href={`https://wa.me/?text=${shareUrl}`}
                    aria-label="Share on Twitter"
                  >
                    <ImWhatsapp className="w-6 h-6" />
                  </a>
                  <a
                    className="border-2 duration-200 ease inline-flex items-center mb-1 mr-1 transition py-3 px-3 rounded-full text-white border-blue-500 bg-blue-500 hover:bg-blue-700 hover:border-blue-700"
                    href={`https://www.instagram.com/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    rel="noopener"
                    title="Instagram"
                    aria-label="Share on Instagram"
                  >
                    <BsInstagram className="w-6 h-6" />
                  </a>
                </div>
                <div className="pr-0 sm:pr-6">
                  <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:pl-12">
                    <CopyToClipboardButton text={shareUrl} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareDetailsModal;
