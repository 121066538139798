import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BiDownArrow, BiMessageDetail } from "react-icons/bi";
import { IoCallOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { RiUserFollowFill } from "react-icons/ri";
import { AiFillStar, AiOutlineShareAlt, AiTwotoneHeart } from "react-icons/ai";
// import CallDetailsModal from "../../Modal/callDetailsModal";
import ChatPopup from "../../components/ChatPopup/chatPopup";
import Review from "../../common/Reviews/review";
import Footer from "../../layouts/Footer/Footer";
import RecommendProducts from "../../common/RecommendProducts/RecommendProducts";
import ContactDetails from "../../common/contact-details/ContactDetails";
import ShareDetailsModal from "../MyBusinessPage/Modal/shareDetailsModal";
import { BsPlusLg } from "react-icons/bs";
import Loading from "../../common/Loading/Loading";
import useProductDetails from "../../utils/hooks/useProductDetails";
import axios from "axios";
import WriteReview from "../../common/Reviews/WriteReview/WriteReview";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginModal } from "../../utils/appSlice";
import ImageMagnifiers from "../../components/ImageMagnifiers";

const ProductDetails = () => {
  const [image, setImage] = useState(1);
  const [callOption, setCallOption] = useState(false);
  const [chatOption, setChatOption] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [writeReviewPopup, setWriteReviewPopup] = useState(false);
  const [reviewProductSummaryData, setReviewProductSummaryData] = useState();
  const [reviewVenderSummaryData, setReviewVenderSummaryData] = useState();
  const [isReview, setIsReview] = useState(false);
  const { postId } = useParams();
  const productInfo = useProductDetails(postId);
  const productData = productInfo?.product;
  const venderData = productInfo?.businessPage;
  const getUserToken = () => JSON.parse(localStorage.getItem("userInfo")).token;
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userToken);
  const [sharePageUrl, setSharePageUrl] = useState();

  const handleShareModal = (item) => {
    setSharePopup(true);
    setSharePageUrl(`products/${item.productId}`);
  };

  const toggleLoginHandler = () => {
    dispatch(loginModal());
  };

  useEffect(() => {
    // console.log("I Viewed", postId);
    insight();
  }, []);

  const insight = async () => {
    const userToken = localStorage.getItem("userInfo");
    const userDetails = JSON.parse(userToken);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/home/campaign-insight?id=${postId}&user_id=${userDetails.customId}&insight_type=POST&action_type=CLICK`,
        {
          id: postId,
          user_id: userDetails.customId,
          insight_type: "POST",
          action_type: "CLICK",
        }
      );

      // setLoading(false);
    } catch (error) {
      console.log("Error insight:", error);
    }
  };

  useEffect(() => {
    if (venderData) {
      getCheckUserIsFollowing();
    }
  }, [venderData, isFollow]);

  async function getCheckUserIsFollowing() {
    try {
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          // "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/is-vendor-followed-by?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}`,
        auth
      );

      const responseData = response.data;
      if (responseData?.success) {
        setIsFollow(true);
      }
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  const followHandleSubmit = async () => {
    try {
      if (userDetails.customId === productInfo?.businessPage?.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/follow-to-vendor?vendorId=${venderData?.businessPageId}&userId=${userDetails.customId}`,

        { vendorId: venderData?.businessPageId, userId: userDetails.customId },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );

      const { data } = response.data;
      setIsFollow(true);
      toast.success(response.data.message);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const unFollowHandleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/unfollow-to-vendor?vendorId=${venderData?.businessPageId}&userId=${userDetails.customId}`,

        { vendorId: venderData?.businessPageId, userId: userDetails.customId },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      setIsFollow(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // Likes Funnctionality

  useEffect(() => {
    if (venderData) {
      getCheckUserIsLiked();
    }
  }, [venderData, isLiked]);

  async function getCheckUserIsLiked() {
    try {
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          // "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/is-liked-by?userId=${userDetails.customId}&productId=${postId}`,
        auth
      );

      const responseData = response.data;
      if (responseData?.success) {
        setIsLiked(true);
      }
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  const likedHandleSubmit = async () => {
    try {
      if (userDetails.customId === productInfo?.businessPage?.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/like-product?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}&productId=${postId}`,

        {
          userId: userDetails.customId,
          vendorId: venderData?.businessPageId,
          productId: postId,
        },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );

      const { data } = response.data;
      toast.success(response.data.message);
      setIsLiked(true);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const unLikeHandleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/unlike-product?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}&productId=${postId}`,

        {
          userId: userDetails.customId,
          vendorId: venderData?.businessPageId,
          productId: postId,
        },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      setIsLiked(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // For Rating the Post

  useEffect(() => {
    if (venderData) {
      getCheckProductRatingSummary();
      getCheckCountPostRating();
    }
  }, [venderData, isReview]);

  async function getCheckProductRatingSummary() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/product-rating-summary?productId=${postId}`
      );

      const responseData = response.data;
      setReviewProductSummaryData(responseData);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  async function getCheckCountPostRating() {
    try {
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          // "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/rating-to-product?userId=${userDetails.customId}&productId=${postId}`,
        auth
      );

      const responseData = response.data;
      // if (responseData?.success) {
      //   setIsLiked(true);
      // }
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  async function productRatingHandler(stars) {
    try {
      if (userDetails.customId === productInfo?.businessPage?.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/product-rating?userId=${userDetails.customId}&productId=${postId}&rating=${stars}`,
        {
          userId: userDetails.customId,
          productId: postId,
          rating: stars,
        },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      setIsReview(true);
      setWriteReviewPopup(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  }

  // Rating vender Summary

  useEffect(() => {
    if (venderData) {
      getVenderReviewSummary();
    }
  }, [venderData, isReview]);

  async function getVenderReviewSummary() {
    try {
      const auth = {
        headers: {
          Authorization: getUserToken(),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/vendor-rating-summary?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}`,
        auth
      );

      const responseData = response.data;
      setReviewVenderSummaryData(responseData);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  async function postRatingHandler(stars) {
    try {
      if (userDetails.customId === productInfo?.businessPage?.businessPageId) {
        toast.error("You are the Vendor");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/vendor-rating?userId=${userDetails.customId}&vendorId=${venderData?.businessPageId}&rating=${stars}`,
        {
          userId: userDetails.customId,
          vendorId: venderData?.businessPageId,
          rating: stars,
        },
        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      setIsReview(true);
      setWriteReviewPopup(false);
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  }

  const forwordArrow = (imageLength) => {
    if (imageLength < productData?.imageUrlList?.length) {
      setImage(imageLength + 1);
    } else setImage(1);
  };

  const backwordArrow = (imageLength) => {
    if (imageLength > 1) {
      setImage(imageLength - 1);
    } else setImage(productData?.imageUrlList?.length);
  };

  return !venderData && !productData ? (
    <Loading />
  ) : (
    <>
      {sharePopup && (
        <ShareDetailsModal
          setSharePopup={setSharePopup}
          sharePageUrl={sharePageUrl}
        />
      )}

      <section className="md:mx-10 my-5 font-poppins border md:p-5 py-2 rounded-lg bg-slate-100">
        <div className="flex flex-wrap mb-10 md:flex justify-between 2xl:mx-20 md:mx-6 mx-4 ">
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 ">
            {productData?.mediaType === 1 ? (
              <video
                className="object-fit w-full lg:h-80 h-72 transform rounded-t-md transition duration-500"
                controls
                src={productData?.video}
                // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                // onMouseOver={(event) => event.target.play()}
                // onMouseOut={(event) => event.target.pause()}
                width="620"
              ></video>
            ) : (
              <div className="sticky top-0  overflow-hidden ">
                <div className="relative mb-6  bg-slate-300">
                  <div className="absolute left-0 transform lg:ml-2 top-1/2 translate-1/2 cursor-pointer">
                    <IoIosArrowBack onClick={() => backwordArrow(image)} />
                  </div>
                  {productData?.imageUrlList
                    .slice(0, productData?.imageUrlList?.length)
                    .map((imageSrc, index) => (
                      <div
                        key={index + 1}
                        className={` ${
                          image === index + 1 ? "" : "hidden"
                        } flex items-center justify-center`}
                      >
                        {/* <img
                          // src={getImageLink(index + 1)} // Get the image link based on the image index
                          src={imageSrc}
                          alt=" Not Found"
                          className="object-fit w-80 lg:h-80 h-72 "
                          loading="lazy"
                        /> */}
                        <ImageMagnifiers
                          src={imageSrc}
                          alt=" Not Found"
                          // className="object-fit w-80 lg:h-80 h-72 "
                          loading="lazy"
                        />
                        {productData?.approvalStatus === "PENDING" ? (
                          ""
                        ) : (
                          <div
                            className="absolute top-2 right-2 p-1 bg-blue-700 rounded-full hover:cursor-pointer"
                            onClick={() => handleShareModal(productData)}
                          >
                            <AiOutlineShareAlt className="h-5 w-6 my-1 text-white" />
                          </div>
                        )}
                      </div>
                    ))}
                  <div className="absolute right-0 transform lg:mr-2 top-1/2 translate-1/2 cursor-pointer">
                    <IoIosArrowForward onClick={() => forwordArrow(image)} />
                  </div>
                </div>

                <div className="flex-wrap hidden -mx-2 md:flex">
                  {productData?.imageUrlList
                    .slice(0, productData?.imageUrlList?.length)
                    .map((imageSrc, index) => (
                      <div key={index} className="w-1/2 p-2 sm:w-1/5">
                        <div className="block border border-transparent">
                          <div>
                            <button onClick={() => setImage(index + 1)}>
                              <img
                                src={imageSrc}
                                alt="Post"
                                className="object-cover w-24 h-24 border border-gray-300"
                                loading="lazy"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h1 className="text-gray-900 text-3xl title-font font-medium">
              {productData?.name}
            </h1>
            <div className="sm:flex mb-2">
              <div className="mt-2 flex items-center">
                <div className="flex items-center">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <AiFillStar
                      key={index}
                      className={`w-5 h-5 ${
                        index < reviewProductSummaryData?.averageRating
                          ? "text-yellow-400"
                          : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
                <p className="ml-2 text-sm font-medium text-gray-500">
                  {reviewProductSummaryData?.oneCount +
                    reviewProductSummaryData?.twoCount +
                    reviewProductSummaryData?.threeCount +
                    reviewProductSummaryData?.fourCount +
                    reviewProductSummaryData?.fiveCount +
                    " "}
                  Ratings
                </p>
                <span className="flex ml-3 pl-6 border-l-2 border-gray-200">
                  <button
                    onClick={
                      userDetails
                        ? () => setWriteReviewPopup(true)
                        : toggleLoginHandler
                    }
                    className="rounded-md border border-[#003764] px-8 py-1 text-sm font-semibold text-[#003764] hover:scale-105"
                  >
                    Rate the Post
                  </button>

                  {writeReviewPopup && (
                    <WriteReview
                      setWriteReviewPopup={setWriteReviewPopup}
                      postRatingHandler={productRatingHandler}
                    />
                  )}
                </span>
              </div>
            </div>
            <p className="leading-relaxed max-h-56 overflow-y-scroll">
              {productData?.description}
            </p>
            <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5"></div>
            <div className="flex justify-between">
              {productData.price !== "" && (
                <span className="title-font font-medium text-2xl text-gray-900">
                  ₹{productData?.price}
                </span>
              )}
              <div className="sm:flex">
                <button
                  type="button"
                  title="call to vendor"
                  className="mb-2 relative flex ml-auto  justify-center items-center bg-[#1da1f2] hover:bg-[#1e86c7] rounded w-32 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  onClick={
                    userDetails
                      ? () => setCallOption(!callOption)
                      : toggleLoginHandler
                  }
                >
                  <IoCallOutline size={18} className="mr-2" />
                  Contact
                </button>

                <button
                  type="button"
                  title="chat with vendor"
                  className="mb-2 flex ml-4  justify-center items-center bg-[#1da1f2] rounded w-32 px-5 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  onClick={
                    userDetails
                      ? () => setChatOption(!chatOption)
                      : toggleLoginHandler
                  }
                >
                  <BiMessageDetail size={18} className="mr-2" />
                  Chat
                </button>
                {isLiked ? (
                  <button
                    className="rounded-full w-10 h-10 text-red-600 bg-gray-200 p-0 border-0 inline-flex items-center justify-center  ml-4"
                    title="to save in wishlist"
                    onClick={unLikeHandleSubmit}
                  >
                    <AiTwotoneHeart size={20} />
                  </button>
                ) : (
                  <button
                    className="rounded-full w-10 h-10 hover:text-red-600 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4"
                    title="to save in wishlist"
                    onClick={
                      userDetails ? likedHandleSubmit : toggleLoginHandler
                    }
                  >
                    <AiTwotoneHeart size={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start bg-white hover:shadow-xl mx-10 px-4 py-3 rounded-md border-teal-500 border-solid sm:flex-row">
          <Link to={"/vender-details/" + productData?.vendorId}>
            <div className="flex">
              {/* company logo */}
              <img
                src={venderData?.profileUrl}
                alt="logo"
                className="-mt-11mb-4 w-20 h-20 sm:mt-0 sm:h-24 sm:my-0 rounded-md ml-10 sm:ml-0"
              />

              <div className="flex-flex-col-justify-between ml-4">
                <h2 className="max-w-xl mt-2 font-bold text-2xl font-heading cursor-pointer text-gray-800 flex items-center gap-2">
                  {venderData?.businessPageName}
                </h2>
                <h1 className="text-lg font-medium cursor-pointer">
                  Catagory: {venderData?.pageCategory}
                </h1>
                <div className="mt-1 flex items-center">
                  <div className="flex items-center">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <AiFillStar
                        key={index}
                        className={`w-5 h-5 ${
                          index < reviewVenderSummaryData?.averageRating
                            ? "text-yellow-400"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                  </div>
                  <p className="ml-2 text-sm font-medium text-gray-500">
                    {reviewVenderSummaryData?.oneCount +
                      reviewVenderSummaryData?.twoCount +
                      reviewVenderSummaryData?.threeCount +
                      reviewVenderSummaryData?.fourCount +
                      reviewVenderSummaryData?.fiveCount +
                      " "}
                    Ratings
                  </p>
                </div>
              </div>
            </div>
          </Link>

          <div className="flex flex-wrap items-center mt-4 mx-4 pt-4 border-t border-gray-500 border-solid sm:ml-auto sm:border-0 sm:pt-0">
            {isFollow ? (
              <button
                type="button"
                className="mb-2 flex bg-[#3b454b] rounded w-28  justify-center items-center  py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                onClick={unFollowHandleSubmit}
              >
                Following
                <RiUserFollowFill size={18} className="mr-2" />
              </button>
            ) : (
              <button
                type="button"
                className="mb-2 flex bg-[#1da1f2] rounded w-28  justify-center items-center  py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                onClick={userDetails ? followHandleSubmit : toggleLoginHandler}
              >
                Follow
                <BsPlusLg size={15} className="ml-2" />
              </button>
            )}
          </div>
        </div>

        {/* <div className="border-t border-gray-400"><hr /></div> */}

        <div className="mt-10 flex flex-wrap lg:flex-nowrap md:m-10 border border-gray-300 rounded-md p-10">
          <div className="w-full">
            <h5 className="border-b border-gray-300 py-4 text-sm font-medium text-gray-800 ">
              Description
            </h5>
            <div className="my-4 max-h-56 overflow-y-scroll max-w-4xl leading-[1.8rem] text-[#6d6d6d]">
              {venderData?.pageDescription}
            </div>
            <div>
              <h5 className="text-lg">Contact Details</h5>
            </div>
            <div className="contact-details mt-4 mb-6 flex w-full flex-wrap">
              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Business Email : &nbsp;
                  </h6>
                  <span
                    className={`inline-block text-sm font-semibold ${
                      !userDetails && "blur-sm"
                    }`}
                  >
                    {venderData?.businessEmail}
                  </span>
                </div>
              </div>

              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Page Catagory :&nbsp;
                  </h6>
                  <span className="inline-block text-sm font-semibold">
                    {venderData?.pageCategory}
                  </span>
                </div>
              </div>

              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Mobile Number :&nbsp;
                  </h6>
                  <span
                    className={`inline-block text-sm font-semibold ${
                      !userDetails && "blur-sm"
                    }`}
                  >
                    {venderData?.businessContactNumber}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <div className="border-b border-gray-900/10">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Social media Links
                </h2>
              </div>

              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Facebook :&nbsp;
                  </h6>
                  <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                    {venderData?.facebookUrl}
                  </span>
                </div>
              </div>

              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Instagram :&nbsp;
                  </h6>
                  <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                    {venderData?.instagramUrl}
                  </span>
                </div>
              </div>

              <div className="mr-0 sm:mr-32">
                <div className="my-2">
                  <h6 className="inline-block text-sm text-gray-800">
                    Youtube :&nbsp;
                  </h6>
                  <span className="inline-block text-sm font-semibold text-blue-900 hover:underline cursor-pointer">
                    {venderData?.youtubeUrl}
                  </span>
                </div>
              </div>

              <div className="mt-4 text-xs text-gray-500 italic text-center">
                Disclaimer: MakeAssure only provides information about the
                product or service. No research has been conducted by MakeAssure
                in this regard, nor is MakeAssure or its staff responsible for
                this. The MakeAssure user is instructed to collect the full
                details of the organization or individual concerned before
                making any payment.
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-5 gap-5 flex-wrap lg:flex-nowrap ">
            {/* Address Section */}
            <div className="ml-0 lg:ml-5 relative">
              <div
                className={`h-fit w-[300px] rounded-xl border-2 border-gray-300 shadow-md sm:w-[350px] ${
                  !userDetails && "blur-sm rounded-xl border-2 border-blue-600"
                }`}
              >
                <iframe
                  title="location"
                  className="relative m-auto w-[300px] sm:w-[350px]"
                  src={`https://maps.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16&output=embed`}
                  width="350"
                  height="200"
                  // frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
                <div className="p-4">
                  <div
                    className="group flex flex-col  rounded-lg bg-gray-300 p-3 text-white"
                    tabindex="1"
                  >
                    <div className="flex cursor-pointer items-center justify-between">
                      <span className="font-bold text-black">Address</span>

                      <BiDownArrow
                        size={15}
                        className=" text-black  transition-all duration-500 group-focus:-rotate-180"
                      />
                    </div>
                    <div className="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
                      <span className="flex text-left text-xs font-medium leading-5 tracking-[0.3px] text-[#6d6d6d]">
                        {venderData?.streetAddress}, {venderData?.village},{" "}
                        {venderData?.city}, {venderData?.district},{" "}
                        {venderData?.country}, {venderData?.zip}
                      </span>
                    </div>
                  </div>

                  {/* <span className="font-bold text-black">Address</span>
                  <span className="flex text-left text-xs font-medium leading-5 tracking-[0.3px] text-[#6d6d6d]">
                    {venderData?.streetAddress}, {venderData?.village},{" "}
                    {venderData?.city}, {venderData?.district},{" "}
                    {venderData?.country}, {venderData?.zip}
                  </span> */}
                  <Link
                    to={`https://www.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16`}
                    target="_blank"
                  >
                    <button className="mt-2 w-full rounded-md bg-gray-300 py-2 px-[8px] text-sm font-bold text-blue-500">
                      Directions
                    </button>
                  </Link>

                  {/* <Link
                    href={`http://maps.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16&output=embed`}
                    passHref
                  >
                    <button
                      onClick={() =>
                        window.open(
                          `http://maps.google.com/maps?q=${venderData?.latitude},${venderData?.longitude}&z=16&output=embed`
                        )
                      }
                      className="mt-2 w-full rounded-md bg-gray-300 py-2 px-[8px] text-sm font-bold text-blue-500"
                    >
                      Directions
                    </button>
                  </Link> */}
                </div>
              </div>
              {!userDetails && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-80 opacity-0 transition-opacity hover:opacity-100">
                  <button
                    className="w-24 flex justify-center items-center text-xs sm:text-sm bg-slate-100 px-2 sm:px-3 py-2 text-blue-500 rounded font-medium shadow border focus:outline-none  hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                    onClick={toggleLoginHandler}
                  >
                    LOGIN
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="md:m-8">
          <Review
            venderData={venderData}
            reviewVenderSummaryData={reviewVenderSummaryData}
            postRatingHandler={postRatingHandler}
            setWriteReviewPopup={setWriteReviewPopup}
            writeReviewPopup={writeReviewPopup}
          />
        </div>
      </section>

      {chatOption && <ChatPopup setChatOption={setChatOption} />}
      {callOption && (
        <ContactDetails setCallOption={setCallOption} venderData={venderData} />
      )}

      {/* <div className="">
        <RecommendProducts />
      </div> */}
      <Footer />
    </>
  );
};

export default ProductDetails;
