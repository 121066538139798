import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk to fetch notifications
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userInfo"));
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          //   "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/notification?user_id=${userDetails.customId}`,
        auth
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Define the async thunk to fetch notifications count
export const fetchNotificationsCount = createAsyncThunk(
  "notifications/fetchNotificationsCount",
  async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userInfo"));
      const auth = {
        headers: {
          Authorization: userDetails.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/notification-count?user_id=${userDetails.customId}`,
        auth
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create the notifications slice
const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    data: null,
    count: 0,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchNotificationsCount.pending, (state) => {
        state.status = "loading"; // You can use a different loading state for count if needed
      })
      .addCase(fetchNotificationsCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.count = action.payload; // Update the count state
      })
      .addCase(fetchNotificationsCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // You can handle count-specific errors if necessary
      });
  },
});

export default notificationsSlice.reducer;
