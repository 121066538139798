import React, { useEffect } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "../../../src/assets/images/brandLogo.png";
import { FiDownload } from "react-icons/fi";
import SearchSection from "../../pages/Home/components/SearchSection";
import useScrollSearch from "../../utils/hooks/useScrollSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMenu,
  loginModal,
  notificationModal,
  toggleMenu,
} from "../../utils/appSlice";
import { fetchNotificationsCount } from "../../utils/notificationSlice";
import { RiPagesLine } from "react-icons/ri";

function Header() {
  const showSearch = useScrollSearch();
  const notificationCount = useSelector((state) => state.notifications.count);
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userToken);
  const userlocation = JSON.parse(localStorage.getItem("location"));

  const userData = useSelector((state) => state.userProfile);

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchNotificationsCount());
    };

    if (userToken) {
      // First, fetch the data immediately when the component mounts
      fetchData();

      // Then, set up an interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 300000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [userToken]);

  const toggleMenuHandler = () => {
    dispatch(toggleMenu());
  };

  const toggleLoginHandler = () => {
    dispatch(loginModal());
  };

  const toggleNotificationHandler = () => {
    dispatch(notificationModal());
  };

  const closeMenuHandler = () => {
    dispatch(closeMenu());
  };

  return (
    <>
      <header className="sticky top-0 z-[50] bg-white border-b-2   transition-all">
        <nav className="flex  items-center relative justify-between px-5 py-2 w-full">
          {/* Header Logo */}
          <div>
            <Link className="cursor-pointer" to="/">
              <img className="w-full h-6" src={Logo} alt="logo" />
            </Link>
          </div>

          <div className="hidden lg:block">
            {showSearch && (
              <SearchSection
                showSearch={showSearch}
                userlocation={userlocation}
              />
            )}
          </div>

          <div className="flex gap-2 md:gap-4 items-center ">
            <Link
              to="https://play.google.com/store/apps/details?id=com.app.makeassure&hl=en"
              target="_blank"
            >
              <button
                className="bg-blue-500 flex justify-center items-center hover:bg-blue-700 text-white md:font-bold py-2 px-1 md:px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                title="download the app"
              >
                <FiDownload className="md:mr-2" />
                <h1 className="hidden md:block text-sm w-28">Download App</h1>
              </button>
            </Link>

            {userData?.userData?.user?.seller ? (
              userToken ? (
                <Link to="/my-business-page" onClick={closeMenuHandler}>
                  <button
                    className="bg-blue-500 gap-1 flex justify-center items-center hover:bg-blue-700 text-white md:font-bold py-2 px-1 md:px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                    title="My Business Page"
                  >
                    <RiPagesLine size={20} />
                    <h1 className="hidden md:block text-sm ">
                      My Business Page
                    </h1>
                  </button>
                </Link>
              ) : (
                <button
                  onClick={toggleLoginHandler}
                  className="bg-blue-500 gap-1 flex justify-center items-center hover:bg-blue-700 text-white md:font-bold py-2 px-1 md:px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                  title="My Business Page"
                >
                  <RiPagesLine size={20} />
                  <h1 className="hidden md:block text-sm ">My Business Page</h1>
                </button>
              )
            ) : userToken ? (
              <Link to="/create-business-page" onClick={closeMenuHandler}>
                <button
                  className="bg-blue-500 gap-1 flex justify-center items-center hover:bg-blue-700 text-white md:font-bold py-2 px-1 md:px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                  title="Create a Business Page"
                >
                  <RiPagesLine size={20} />
                  <h1 className="hidden md:block text-sm ">
                    Create a Business Page
                  </h1>
                </button>
              </Link>
            ) : (
              <button
                onClick={toggleLoginHandler}
                className="bg-blue-500 gap-1 flex justify-center items-center hover:bg-blue-700 text-white md:font-bold py-2 px-1 md:px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105"
                title="Create a Business Page"
              >
                <RiPagesLine size={20} />
                <h1 className="hidden md:block text-sm ">
                  Create a Business Page
                </h1>
              </button>
            )}

            {!notificationCount?.message < 1 && userToken && (
              <div
                className="relative flex cursor-pointer"
                onClick={toggleNotificationHandler}
              >
                {notificationCount?.message > 0 ? (
                  <span className="bg-pink-500 w-6 h-6 rounded-full flex items-center justify-center text-white poppins absolute -right-2 -top-2">
                    {notificationCount?.message}
                  </span>
                ) : (
                  ""
                )}

                <IoMdNotificationsOutline
                  className="text-black cursor-pointer"
                  size={30}
                />
              </div>
            )}

            {userToken ? (
              <div className=" z-10 hover:ring-4 user cursor-pointer ring-blue-700/30 rounded-full bg-cover bg-center ">
                <div
                  className="relative flex cursor-pointer"
                  onClick={toggleMenuHandler}
                >
                  {/* <!-- User avatar --> */}
                  <img
                    src={userData?.userData?.user?.profile}
                    className="rounded-full h-10 w-10"
                    alt="noimage"
                    loading="lazy"
                  />
                </div>
              </div>
            ) : (
              <div>
                <button
                  className="w-24 flex justify-center items-center text-xs sm:text-sm bg-slate-100 px-2 sm:px-3 py-2 text-blue-500 rounded font-medium shadow border focus:outline-none  hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={toggleLoginHandler}
                >
                  LOGIN
                  <BsArrowRight size={20} className="ml-2" />
                </button>
              </div>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
