import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  description: Yup.string().required("Description is required"),
});

const initialValues = {
  name: "",
  email: "",
  description: "",
};

const Report = ({ handleWriteReportPopup, venderData }) => {
  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission here
    // console.log(values);
    const userToken = localStorage.getItem("userInfo");
    const userDetails = JSON.parse(userToken);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/send-report?userId=${userDetails?.customId}&full_name=${values?.name}&email=${values?.email}&message=${values?.description}&report_type="VENDER"&report_to=${venderData?.businessPageId}`,
        {
          userId: userDetails.customId,
          full_name: values?.name,
          report_type: "VENDER",
          report_to: venderData?.businessPageId,
          email: values?.email,
          message: values?.description,
        },

        {
          headers: {
            Authorization: userDetails.token, // Replace with your actual token
          },
        }
      );
      const { data } = response.data;
      toast.success(response.data.message);
      handleWriteReportPopup();
      resetForm();
    } catch (error) {
      console.log("Error fetching product lists:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div
        className="relative z-[1002] h-fit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-hidden">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <div className="bg-gray-200  rounded-lg w-96">
              <div className="flex justify-between py-4 px-5 bg-slate-100 mb-4">
                <div className="flex items-center">
                  <h2 className="text-xl leading-snug font-bold">
                    Report this Page
                  </h2>
                </div>
                <div
                  className="cursor-pointer text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                  onClick={handleWriteReportPopup}
                >
                  <RxCross2 size={25} />
                </div>
              </div>

              <div className="p-4">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className=" block text-gray-700 text-sm font-bold mb-2 text-left"
                      >
                        Name
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="border rounded w-full p-2"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      >
                        Email
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="border rounded w-full p-2"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="description"
                        className="block text-gray-700 text-sm font-bold mb-2 text-left"
                      >
                        Description
                      </label>
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        className="border rounded w-full p-2"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
                    >
                      Submit
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
