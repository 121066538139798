import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FlagIcon from "../assets/svg/indianflag.svg";
import OtpInputForm from "../components/OtpInput/otpInputForm";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../common/Loading/Loading";
import { useDispatch } from "react-redux";
import { closeLoginModal } from "../utils/appSlice";
import { useNavigate } from "react-router-dom";
import { fetchUserDetails } from "../utils/userProfileSlice";
import { fetchNotifications } from "../utils/notificationSlice";

const API_URL = process.env.REACT_APP_API_URL;

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be a 10-digit number")
    .required("Mobile Number is required"),
});

const LoginForm = ({ handleSignUp }) => {
  const [signInMobileNumber, setSignInMobileNumber] = useState("");
  const [isType, setIsType] = useState("otp");
  const [otpData, setOtpData] = useState("");
  const [showSignInOtp, setShowSignInOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    mobileNumber: "",
  };

  const handleOtpChange = (otp) => {
    setOtpData(otp);
  };

  const axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Content-Type": "application/json",
    },
  };

  const handleMobileNumberInput = (event) => {
    const value = event.target.value;
    // Allow only numeric characters by removing non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedValue = numericValue.slice(0, 10);

    // Update the input field value with the limited numeric value
    event.target.value = limitedValue;
    setSignInMobileNumber(limitedValue);
  };

  const sendOtp = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/users/send-otp?mobileNumber=${values.mobileNumber}`,
        axiosConfig
      );
      const responseData = response.data;
      if (responseData.success && isType === "otp") {
        setSignInMobileNumber(values.mobileNumber);
        setShowSignInOtp(true);
        setIsType("submit");
        toast.success("Successfully sent OTP");
      }
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
      toast.error(error?.response?.data?.message);
      if (
        error?.response?.data?.message?.includes(
          "Look like you haven't registered yet"
        )
      ) {
        handleSignUp();
      }
    }
  };

  const handleSignInSubmit = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/users/sign-in?mobileNumber=${signInMobileNumber}&otp=${otpData}`,
        axiosConfig
      );
      const responseData = response.data;

      if (responseData.success && isType === "submit") {
        localStorage.setItem("userInfo", JSON.stringify(responseData.user));
        localStorage.setItem(
          "location",
          JSON.stringify({
            lat: responseData.user.userLat,
            lng: responseData.user.userLong,
          })
        );

        dispatch(closeLoginModal());
        dispatch(fetchUserDetails());
        dispatch(fetchNotifications());
        navigate("/");
        toast.success("Successfully Login");
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showSignInOtp ? (
        <div>
          <div className="text-center">
            <OtpInputForm
              className="otp-box h-9 w-9 rounded-[4px] p-[10px] text-center "
              mobileNumber={signInMobileNumber}
              handleOtpChange={handleOtpChange}
              handleSignInSubmit={handleSignInSubmit}
              type="LOGIN"
            />
          </div>
          {isType === "submit" && (
            <button
              className="inline-block px-7 py-3 my-6 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              onClick={handleSignInSubmit}
            >
              Submit
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <h5 className="text-xl font-medium text-gray-900 mt-5">
            SignIn to our platform
          </h5>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => sendOtp(values)}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4 flex items-center">
                  <div className="mr-2">
                    <img
                      src={FlagIcon}
                      alt="India Flag"
                      width="25"
                      height="15"
                    />
                  </div>
                  <div className="text-gray-700 text-sm font-bold">+91</div>
                  <div className="ml-2 flex-1">
                    <Field
                      type="text"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="Continue with mobile number"
                      className={`appearance-none border ${
                        errors.mobileNumber && touched.mobileNumber
                          ? "border-red-500"
                          : "border-gray-200"
                      } rounded w-full md:w-64 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                      onInput={handleMobileNumberInput}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="mobileNumber"
                  component="p"
                  className="text-red-500 text-xs italic"
                />
                <div className="my-3 mx-auto flex w-fit flex-col space-y-3">
                  {isType === "otp" && (
                    <button
                      type="submit"
                      className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Send OTP
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default LoginForm;
