import axios from "axios";

export const postInsightHandler = async (
  productId,
  insight_type = "POST",
  action_type = "VIEW"
) => {
  const userToken = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userToken);
  if (!productId) throw new Error("postInsightHandler productId is required");
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/home/campaign-insight?id=${productId}&user_id=${userDetails.customId}&insight_type=${insight_type}&action_type=${action_type}`,
      {
        id: productId,
        insight_type,
        user_id: userDetails.customId,
        action_type,
      }
    );
  } catch (error) {
    console.log("Error postInsightHandler:", error);
  }
};
