import React, { forwardRef, useEffect, useState } from "react";
import { AiFillHome, AiTwotoneHeart } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdCancel } from "react-icons/md";
import { RiFeedbackLine, RiPagesLine } from "react-icons/ri";
import { SlUserFollowing } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu, logoutModal, toggleMenu } from "../../utils/appSlice";
import { fetchUserDetails } from "../../utils/userProfileSlice";

const Sidebar = forwardRef(({ handleLogout }, ref) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMenuOpen = useSelector((store) => store.app.isMenuOpen);

  const userData = useSelector((state) => state.userProfile.userData); // Update the slice name
  // const userLoading = useSelector((state) => state.userProfile.userStatus === "loading"); // Update the slice name
  // const userError = useSelector((state) => state.userProfile.userError); // Update the slice name

  // if (userLoading) {
  //   return <div>Loading user data...</div>;
  // }

  // if (userError) {
  //   return <div>Error: {userError}</div>;
  // }

  useEffect(() => {
    // Dispatch the action
    dispatch(fetchUserDetails());
  }, [dispatch]);
  

  const toggleMenuHandler = () => {
    dispatch(toggleMenu());
  };

  const LogoutHandler = () => {
    dispatch(logoutModal());
  };

  const closeMenuHandler = () => {
    dispatch(closeMenu());
  };

  // Early Return pattern
  if (!isMenuOpen) return null;

  return (
    <div
      className="relative z-[1001] h-fit"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-white bg-opacity-50  transition-opacity"></div>
      <AnimatePresence>
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            ease: "easeInOut",
            stiffness: 300,
            damping: 30,
          }}
          ref={ref}
          className="sidebar fixed z-20 top-0 bottom-0 right-0 p-2 w-[300px] overflow-y-auto text-center border-l border-gray-300 bg-white"
        >
          <div className="text-gray-100 text-xl">
            <div className="p-2.5 mt-1 flex items-center">
              <div
                className="cursor-pointer absolute top-0 left-0 mt-4 ml-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                onClick={toggleMenuHandler}
              >
                <MdCancel size={25} />
              </div>
            </div>
            {/* <h1 className="font-medium text-black text-sm ml-3 flex justify-center items-center">
              Welcome Captain
            </h1> */}
            {/* <span className="font-medium text-yellow-500">
              Your Earn points: 100
            </span> */}
            <div className="mt-5 bg-gray-600 h-[1px]"></div>
          </div>
          {location.pathname !== "/" && (
            <Link to="/" onClick={closeMenuHandler}>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
                <AiFillHome size={20} />
                <span className="text-[15px] ml-4  font-bold hover:text-white">
                  Home
                </span>
              </div>
            </Link>
          )}
          <Link to="/my-profile" onClick={closeMenuHandler}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
              <CgProfile size={20} />
              <span className="text-[15px] ml-4  font-bold hover:text-white">
                My Profile
              </span>
            </div>
          </Link>
          {userData?.user?.seller ? (
            <Link to="/my-business-page" onClick={closeMenuHandler}>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
                <RiPagesLine size={20} />
                <span className="text-[15px] ml-4  font-bold hover:text-white">
                  My Business Page
                </span>
              </div>
            </Link>
          ) : (
            <Link to="/create-business-page" onClick={closeMenuHandler}>
              <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
                <RiPagesLine size={20} />
                <span className="text-[15px] ml-4  font-bold hover:text-white">
                  Create a Business Page
                </span>
              </div>
            </Link>
          )}

          {/* <Link>
          <div
            className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600"
            onClick={() => setSidebarScroll(false)}
          >
            <HiOutlineChatBubbleBottomCenterText size={20} />
            <span className="text-[15px] ml-4  font-bold hover:text-white">
              Chats (only for venders)
            </span>
          </div>
        </Link> */}

          <Link to="/wishlist" onClick={closeMenuHandler}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
              <AiTwotoneHeart size={20} />
              <span className="text-[15px] ml-4  font-bold hover:text-white">
                Liked Posts
              </span>
            </div>
          </Link>

          <Link to="/following" onClick={closeMenuHandler}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
              <SlUserFollowing size={20} />
              <span className="text-[15px] ml-4  font-bold hover:text-white">
                Following Venders
              </span>
            </div>
          </Link>

          <Link to="/refer-&-earn" onClick={closeMenuHandler}>
            <div className="p-2.5 mt-3 flex justify-between items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
              <div className="flex">
                <GiTakeMyMoney size={20} />
                <span className="text-[15px] ml-4  font-bold hover:text-white">
                  Refer & Earn
                </span>
              </div>
              {/* <span className="font-medium text-yellow-500 hover:text-white">100 points</span> */}
            </div>
          </Link>
          <Link to="/feedback" onClick={closeMenuHandler}>
            <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600">
              <RiFeedbackLine size={20} />
              <span className="text-[15px] ml-4  font-bold hover:text-white">
                Bug report & Feedback
              </span>
            </div>
          </Link>

          <div
            className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-500 hover:text-white text-gray-600"
            onClick={LogoutHandler}
          >
            <FiLogOut size={20} />
            <span className="text-[15px] ml-4  font-bold hover:text-white">
              Logout
            </span>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
});

export default Sidebar;
