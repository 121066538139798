import React, { useEffect, useRef, useState } from "react";
import { TfiLocationPin } from "react-icons/tfi";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineMyLocation } from "react-icons/md";

const AddressField = ({ handleLocationSelect }) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [locationInfo, setLocationInfo] = useState({
  //   lat: null,
  //   lng: null,
  // });
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleLocationInputChange = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    // Call the Google Maps Places API to get autocomplete predictions
    const service = new window.google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: input,
        componentRestrictions: { country: "IN" }, // Filter results to India
      },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSearchResults(predictions);
        }
      }
    );
  };

  const handleSelectPlace = (placeId, description) => {
    if (description === "Detect your location") {
      // Ask the user to enable their location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(
              { location: { lat: latitude, lng: longitude } },
              (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                  if (results[0]) {
                    setSearchInput(results[0].formatted_address);
                    setSearchResults([]); // Clear the search results
                  }
                }
              }
            );
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported");
      }
    } else {
      // Call the Google Maps Places API to get details of the selected place
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      service.getDetails({ placeId: placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          handleLocationSelect({
            lat: place?.geometry?.location?.lat(), // New latitude value
            lng: place?.geometry?.location?.lng(),
            address: description,
          });

          // setLocationInfo({
          //   lat: place?.geometry?.location?.lat(), // New latitude value
          //   lng: place?.geometry?.location?.lng(), // New longitude value
          // });
          // Do something with the selected place details
        }
      });

      setSearchInput(description); // Set the selected place's description as the search input
      setSearchResults([]); // Clear the search results
    }
  };
  const handleClearInput = () => {
    setSearchInput("");
    inputRef.current?.focus();
  };

  useEffect(() => {
    // Close the dropdown when the user clicks anywhere outside of it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="mb-4 col-span-full w-full flex flex-col relative xs:flex-wrap sm:flex-col ">
        <label
          htmlFor="address"
          className="block text-gray-700 text-sm font-bold mb-2 text-left"
        >
          Address
        </label>
        <div className="mt-1 flex items-center">
          <input
            ref={inputRef}
            type="text"
            autoComplete="off"
            id="simple-search"
            value={searchInput}
            onChange={handleLocationInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search Location"
          />
          {searchInput && (
            <button
              className="absolute right-0 mr-3 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={handleClearInput}
            >
              <RxCross2 className="w-4 h-4" />
            </button>
          )}
        </div>

        {searchResults.length > 0 && (
          <ul
            ref={dropdownRef}
            className="absolute max-h-32 overflow-y-scroll left-0 top-14 z-20 w-full mt-2 bg-white border border-gray-300 rounded shadow-md"
          >
            <li
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelectPlace(null, "Detect your location")}
            >
              <div className="relative">
                <MdOutlineMyLocation className="absolute mt-1 text-gray-400 w-5 h-5" />
                <div className="ml-7">Detect your location</div>
              </div>
            </li>
            {searchResults.map((result) => (
              <li
                key={result.place_id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-gray-500"
                onClick={() =>
                  handleSelectPlace(result.place_id, result.description)
                }
              >
                <div className="relative">
                  <TfiLocationPin className="absolute mt-1 text-gray-400 w-5 h-5" />
                  <div className="ml-7">{result.description}</div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AddressField;
