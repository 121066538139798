import React from "react";
// import Chat from "../../common/Chat/chat";
import { RxCross2 } from "react-icons/rx";

const ChatPopup = ({ setChatOption }) => {
  return (
    <>
      <div
        className="relative z-[1002] h-fit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-hidden">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            {/* Component start */}
            <div className="bg-gray-200  rounded-lg w-96">
              <div className="flex justify-between py-4 px-5 bg-slate-100 mb-4">
                <div className="flex items-center">
                  <h2 className="text-xl leading-snug font-bold">Chat</h2>
                </div>
                <div
                  className="cursor-pointer text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                  onClick={() => setChatOption(false)}
                >
                  <RxCross2 size={25} />
                </div>
              </div>

              <div className="container mx-auto px-4 sm:px-8">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                  <div className="inline-block min-w-full  rounded-lg overflow-hidden">
                    <div className="flex justify-center items-center my-10">
                      <h2 class="text-2xl font-bold tracking-tight text-gray-900">
                        Upcoming Feature!
                      </h2>
                    </div>
                    {/* <Chat setChatOption={setChatOption}/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPopup;
