import React, { useState } from "react";
import LoginForm from "../../auth/LoginForm";
import SignupForm from "../../auth/SignupForm";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { closeLoginModal, loginModal } from "../../utils/appSlice";
import { Link } from "react-router-dom";

const Login = () => {
  const [signUp, setSignUp] = useState(false);
  const dispatch = useDispatch();

  const handleSignUp = () => {
    setSignUp(!signUp);
  };

  const toggleLoginHandler = () => {
    dispatch(loginModal());
  };

  const isLoginOpen = useSelector((store) => store.app.isLoginOpen);

  // Early Return pattern
  if (!isLoginOpen) return null;

  return (
    <>
      <div
        className="relative z-[1001] h-fit"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        {/* <AnimatePresence>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: -50,
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className="absolute z-10 p-5 h-auto w-full max-w-md rounded text-white"
          > */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <section className="flex flex-col items-center justify-center w-fit text-gray-800 p-10">
              <div className="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg overflow-hidden">
                <div className="flex justify-between py-4 px-5 bg-slate-200">
                  <div className="flex items-center">
                    <h2 className="text-xl leading-snug font-bold">
                      Welcome to MakeAssure
                    </h2>
                  </div>
                  <div
                    className="cursor-pointer text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                    onClick={() => dispatch(closeLoginModal())}
                  >
                    <RxCross2 className="w-5 h-5" />
                  </div>
                </div>

                <div className="overflow-auto">
                  <div className="md:mx-5 sm:mx-2">
                    {signUp ? (
                      <>
                        <SignupForm />
                        <div className="mb-2  text-center text-sm">
                          Already have an account ?
                          <button onClick={handleSignUp}>
                            <div className="text-blue-700 underline">
                              Sign In
                            </div>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <LoginForm handleSignUp={handleSignUp} />

                        <div className="mb-2  text-center text-sm">
                          Do not have an account ?
                          <button onClick={handleSignUp}>
                            <div className="text-blue-700 underline">
                              Sign Up
                            </div>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="text-xs text-gray-500 justify-center italic text-center  my-2 mx-5 flex flex-wrap">
                    By signing up, you agree to the&nbsp;
                    <u className="text-blue-600 cursor-pointer">
                      <Link
                        to="/terms-and-conditions"
                        onClick={toggleLoginHandler}
                      >
                        Terms & Conditions
                      </Link>
                    </u>
                    &nbsp;&&nbsp;
                    <u className="text-blue-600 cursor-pointer">
                      <Link to="/privacy-policy" onClick={toggleLoginHandler}>
                        Privacy Policy
                      </Link>
                    </u>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* </motion.div>
        </AnimatePresence> */}
      </div>
    </>
  );
};

export default Login;
