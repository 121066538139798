import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { SlLocationPin } from "react-icons/sl";

const ContactDetails = ({ setCallOption, venderData }) => {
  return (
    <div
      className="relative z-[1002] h-fit"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-hidden">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          {/* Component start */}
          <div className="bg-gray-200  rounded-lg w-96">
            <div className="flex justify-between py-4 px-5 bg-slate-100 mb-4">
              <div className="flex items-center">
                <h2 className="text-xl leading-snug font-bold">
                  Contact Details
                </h2>
              </div>
              <div
                className="cursor-pointer text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                onClick={() => setCallOption(false)}
              >
                <RxCross2 size={25} />
              </div>
            </div>

            <div className="container mx-auto px-4 sm:px-8">
              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                <div className="inline-block min-w-full  rounded-lg overflow-hidden">
                  <div className="h-full pr-6">
                    <ul className="mb-6 md:mb-0 flex flex-col items-center justify-center">
                      <li className="flex">
                        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                          <SlLocationPin />
                        </div>
                        <div className="ml-4 mb-4">
                          <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900 ">
                            Address
                          </h3>
                          <p className="text-gray-500 ">
                            {venderData?.streetAddress}, {venderData?.village},
                          </p>
                          <p className="text-gray-500 ">
                            {venderData?.city}, {venderData?.district},
                          </p>
                          <p className="text-gray-500 ">
                            {venderData?.country}, {venderData?.zip}
                          </p>
                        </div>
                      </li>
                      <li className="flex">
                        <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                          <FiPhoneCall />
                        </div>
                        <div className="ml-4 mb-4">
                          <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                            Contact
                          </h3>
                          <p className="text-gray-600 ">
                            Mobile: {venderData?.businessContactNumber}
                          </p>
                          <p className="text-gray-600">
                            Mail: {venderData?.businessEmail}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
