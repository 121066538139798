import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(emailRegex, "Invalid email format")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = () => {
  const initialValues = {
    fullName: "",
    email: "",
    message: "",
  };

  const handleNameInput = (event, setFieldValue) => {
    const value = event.target.value;
    // Allow only alphabetic characters by removing non-alphabetic characters
    const alphabeticValue = value.replace(/[^A-Za-z]/g, "");

    // Update the input field value with the alphabetic value
    event.target.value = alphabeticValue;

    // Set the Formik state with the alphabetic value
    setFieldValue("fullName", alphabeticValue);
  };

  const handleSubmit = async (
    values,
    { resetForm, setFieldError, setSubmitting }
  ) => {
    const userToken = localStorage.getItem("userInfo");
    const userDetails = JSON.parse(userToken);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/business/send-feedback?userId=${userDetails.customId}&full_name=${values.fullName}&email=${values.email}&message=${values.message}`,
        {
          userId: userDetails.customId,
          full_name: values.fullName,
          email: values.email,
          message: values.message,
        },
        {
          headers: {
            Authorization: userDetails.token,
          },
        }
      );
      toast.success(response.data.message);
      resetForm();
    } catch (error) {
      setFieldError(
        "submitError",
        "Error sending feedback. Please try again later."
      );
      console.error("Error sending feedback:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="text-gray-700 body-font relative">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Contact Us
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Lets talk about everything!
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="lg:w-1/2 md:w-2/3 mx-auto">
                  <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2">
                      <label
                        htmlFor="fullName"
                        className="leading-7 text-sm text-gray-600"
                      >
                        Full Name
                      </label>
                      <Field
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        onChange={(event) =>
                          handleNameInput(event, setFieldValue)
                        }
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="p-2 w-1/2">
                      <label
                        htmlFor="email"
                        className="leading-7 text-sm text-gray-600"
                      >
                        Email
                      </label>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="p-2 w-full">
                      <label
                        htmlFor="message"
                        className="leading-7 text-sm text-gray-600"
                      >
                        Message
                      </label>
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="p-2 w-full">
                      <button
                        type="submit"
                        className="flex mx-auto text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
      <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
        <a className="text-indigo-500">Email : help@makeassure.com</a>
      </div>
    </>
  );
};

export default ContactUs;
