import "./App.css";
import store from "../src/utils/store/store";
import { Provider } from "react-redux";
import Loading from "./common/Loading/Loading";
import { Suspense, useEffect, useRef, useState } from "react";
import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import VenderDetailsPage from "./pages/VenderDetailsPage/VenderDetailsPage";
import ResultBoxPage from "./pages/ResultBoxPage/resultBoxPage";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import SearchResults from "./pages/SearchResults/SearchResults";
import Body from "./components/Body";
import Error404 from "./components/404/Error404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./pages/FooterPages/AboutUs";
import PrivacyPolicy from "./pages/FooterPages/PrivacyPolicy";
import TermsAndConditions from "./pages/FooterPages/TermsAndConditions";
import RefundAndCancellationPolicy from "./pages/FooterPages/RefundAndCancellationPolicy";
import ServiceAndContentPolicy from "./pages/FooterPages/ServiceAndContentPolicy";
import ContactUs from "./pages/FooterPages/ContactUs";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";

const MyProfile = lazy(() => import("./pages/MyProfile/MyProfile"));
const CreateBusinessPage = lazy(() =>
  import("./pages/CreateBusinessPage/CreateBusinessPage")
);
const Home = lazy(() => import("./pages/Home/Home"));
const ReferAndEarn = lazy(() => import("./pages/ReferAndEarn/ReferAndEarn"));
const Feedback = lazy(() => import("./pages/FeedBack/Feedback"));
const MyBusinessPage = lazy(() =>
  import("./pages/MyBusinessPage/MyBusinessPage")
);
const WishLists = lazy(() => import("./pages/WishList/WishLists"));
const FollowVenders = lazy(() => import("./pages/followVenders/FollowVenders"));

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const refreshTimeout = useRef(null);
  // // This is for user change the tab and come back then it should refresh

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       // Clear the existing timeout
  //       clearTimeout(refreshTimeout.current);

  //       // Set a new timeout for 2 minutes (120,000 milliseconds)
  //       refreshTimeout.current = setTimeout(() => {
  //         // Page is visible after 2 minutes of inactivity, perform your refresh logic here
  //         window.location.reload();
  //       }, 120000);
  //     } else {
  //       // Page is not visible, clear the timeout
  //       clearTimeout(refreshTimeout.current);
  //     }
  //   };

  //   // Add event listener for visibility change
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   // Clean up the event listener and timeout on component unmount
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     clearTimeout(refreshTimeout.current);
  //   };
  // }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOnline) {
    return (
      <>
        <div className="bg-gray-200 w-full px-16 md:px-0 h-screen flex items-center justify-center">
          <div className="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg shadow-2xl">
            <p className="text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4">
              No internet connection
            </p>
            <p className="text-gray-500 mt-4 pb-4 border-b-2 text-center">
              Please Check your internet Connection.
            </p>
          </div>
        </div>
      </>
    );
  }

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Body />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "my-profile",
          element: (
            <Suspense fallback={<Loading />}>
              <MyProfile />
            </Suspense>
          ),
        },
        {
          path: "create-business-page",
          element: (
            <Suspense fallback={<Loading />}>
              <CreateBusinessPage />
            </Suspense>
          ),
        },
        {
          path: "my-business-page",
          element: (
            <Suspense fallback={<Loading />}>
              <MyBusinessPage />
            </Suspense>
          ),
          children: [
            {
              path: "products/:postId",
              element: <ProductDetails />,
            },
          ],
        },
        {
          path: "wishlist",
          element: (
            <Suspense fallback={<Loading />}>
              <WishLists />
            </Suspense>
          ),
        },
        {
          path: "/following",
          element: (
            <Suspense fallback={<Loading />}>
              <FollowVenders />
            </Suspense>
          ),
        },
        {
          path: "/refer-&-earn",
          element: (
            <Suspense fallback={<Loading />}>
              <ReferAndEarn />
            </Suspense>
          ),
        },
        {
          path: "/feedback",
          element: (
            <Suspense fallback={<Loading />}>
              <Feedback />
            </Suspense>
          ),
        },
        {
          path: "products/:postId",
          element: <ProductDetails />,
        },
        {
          path: "vender-details/:venId",
          element: <VenderDetailsPage />,
        },
        {
          path: "/lists",
          element: <SearchResults />,
        },
        // {
        //   path: "*",
        //   element: <Error404 />,
        // },
        // {
        //   path: "/search-results",
        //   element: <ResultBoxPage />,
        // },

        {
          path: "/about",
          element: <AboutUs />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "/refund-and-cancellation-policy",
          element: <RefundAndCancellationPolicy />,
        },
        {
          path: "/service-and-content-policy",
          element: <ServiceAndContentPolicy />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/delete-account",
          element: <DeleteAccount />,
        },
      ],
    },
  ]);

  return (
    <>
      <Provider store={store}>
        <Authorization>
          <RouterProvider router={appRouter} />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
          />
        </Authorization>
      </Provider>
    </>
  );
}

const Authorization = ({ children }) => {
  const [isLoggedIn, setIsLogged] = useState(false);
  const defaultLocation = {
    lat: "20.2960587",
    lng: "85.8245398",
  };

  const userDetails = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (userDetails) {
      setIsLogged(true);
      const location = {
        lat: userDetails.userLat,
        lng: userDetails.userLong,
      };

      localStorage.setItem("location", JSON.stringify(location));
    } else {
      localStorage.setItem("location", JSON.stringify(defaultLocation));

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            localStorage.setItem(
              "location",
              JSON.stringify({ lat: latitude, lng: longitude })
            );
            // window.location.reload();
          },
          (error) => {
            // setError("Error getting location: " + error.message);
          }
        );
      }
    }
  }, []);

  return <>{children}</>;
};
export default App;
