import axios from "axios";
import { useEffect, useState } from "react";

const useProductDetails = (postId) => {
  const [productInfo, setProuctInfo] = useState(null);

  useEffect(() => {
    fetchProductDetails();
  }, []);

  async function fetchProductDetails() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/home/product-detail?productId=${postId}`
      );

      const responseData = response.data;
      setProuctInfo(responseData);
    } catch (error) {
      console.log("Error fetching product lists:", error);
    }
  }

  return productInfo;
};

export default useProductDetails;
