import React from "react";

function PrivacyPolicy() {
  return (
    <div className="bg-gray-100 py-12 px-4">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 underline">
          PRIVACY &amp; POLICY
        </h1>
        <p className="mb-4">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p className="mb-4">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <p className="mb-4">
          This Privacy Policy is an electronic record in the form of an
          electronic contract formed under the information Technology Act, 2000
          and the rules made thereunder and the amended provisions pertaining to
          electronic documents / records in various statutes as amended by the
          information Technology Act, 2000. This Privacy Policy does not require
          any physical, electronic or digital signature.
        </p>
        <p className="mb-4">
          This document is published and shall be construed in accordance with
          the provisions of the Information Technology (reasonable security
          practices and procedures and sensitive personal data of information)
          rules, 2011 under Information Technology Act, 2000; that require
          publishing of the Privacy Policy for collection, use, storage and
          transfer of sensitive personal data or information.
        </p>
        <p className="mb-4">
          Please read this Privacy Policy carefully by using the Website, you
          indicate that you understand, agree and consent to this Privacy
          Policy. If you do not agree with the terms of this Privacy Policy,
          please do not use this Website.
        </p>

        <h1 className="text-2xl font-semibold mb-6 underline">
          INTERPRETATION AND DEFINITIONS
        </h1>

        <h2 className="text-xl font-semibold mb-4 text-blue-400">
          Interpretation
        </h2>
        <p className="mb-4">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>

        <h2 className="text-xl font-semibold mb-4 text-blue-400">
          Definitions
        </h2>
        <p className="mb-4">For the purposes of this Privacy Policy:</p>
        <ul className="list-disc pl-6">
          <li>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            &quot;control&quot; means ownership of 50% or more of the shares,
            equity interest or other securities entitled to vote for election of
            directors or other managing authority.
          </li>
          <li>
            <strong>Application</strong> refers to MakeAssure, the software
            program provided by the Company.
          </li>
          <li>
            <strong>Company</strong> (referred to as either &quot;the
            Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
            this Agreement) refers to MakeAssure Services Pvt. Ltd.
            (makeassureindia@gmail.com)
          </li>
          <li>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </li>
          <li>
            <strong>Country</strong> refers to: India
          </li>
          <li>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <strong>Personal Data </strong> is any information that relates to
            an identified or identifiable individual.
          </li>
          <li>
            <strong>Service </strong>
            refers to the Application or the Website or both.
          </li>
          <li>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
            <strong>Website</strong> refers to MakeAssure, accessible from
            https://www.MakeAssure.com
          </li>
          <li>
            <strong>You</strong> mean the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>

        <h1 className="text-2xl font-semibold my-6 underline">
          COLLECTING AND USING YOUR PERSONAL DATA
        </h1>

        <h2 className="text-xl font-semibold mb-4 text-blue-400">
          Types of Data Collected
        </h2>
        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          Personal Data
        </h2>

        <p className="mb-4">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
          <ul className="list-disc pl-6 mt-6">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
          </ul>
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">Usage Data</h2>

        <p className="mb-4">
          Usage Data is collected automatically when using the Service.
        </p>
        <p className="mb-4">
          Usage Data may include information such as Your Device&#39;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p className="mb-4">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p className="mb-4">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          Information Collected while Using the Application
        </h2>

        <p className="mb-4">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
          <ul className="list-disc pl-6 mt-6">
            <li>Information regarding your location</li>
            <li>
              Information from your Device&#39;s phone book (contacts list)
            </li>
            <li>
              Pictures and other information from your Device&#39;s camera and
              photo library
            </li>
          </ul>
        </p>
        <p className="mb-4">
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company&#39;s servers and/or a Service Provider&#39;s server or it may
          be simply stored on Your device.
        </p>
        <p className="mb-4">
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          TRACKING TECHNOLOGIES AND COOKIES
        </h2>

        <p className="mb-4">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyse Our Service. The technologies We use may
          include:
        </p>
        <ul className="list-disc pl-6 mt-6">
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons. </strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p className="my-4">
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser.
        </p>

        <p className="my-4">
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            <strong>Necessary / Essential Cookies</strong>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <strong>Functionality Cookies</strong>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to re-
              enter your preferences every time You use the Website.
            </p>
          </li>
          <p className="my-4">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
        </ul>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          USE OF YOUR PERSONAL DATA
        </h2>

        <p className="mb-4">
          The Company may use Personal Data for the following purposes:
        </p>

        <ul className="list-disc pl-6 mt-6">
          <ol>
            <strong>To provide and maintain our Service,</strong>including to
            monitor the usage of our Service.
          </ol>
          <ol>
            <strong>To manage Your Account:</strong>to manage Your registration
            as a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </ol>
          <ol>
            <strong>For the performance of a contract:</strong>the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </ol>
          <ol>
            <strong>To contact You:</strong>To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application&#39;s push notifications regarding
            updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation.
          </ol>
          <ol>
            <strong>To provide You</strong>with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </ol>
          <ol>
            <strong>To manage Your requests:</strong>To attend and manage Your
            requests to Us.
          </ol>
          <ol>
            <strong>For business transfers:</strong>We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </ol>
          <ol>
            <strong>For other purposes:</strong>We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </ol>
        </ul>

        <p className="my-4">
          We may share Your personal information in the following situations:
        </p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            <strong>With Service Providers:</strong>We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <strong>For business transfers:</strong>We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </li>
          <li>
            <strong>With Affiliates:</strong>We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners:</strong>We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </li>
          <li>
            <strong>With other users:</strong>When You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent:</strong>We may disclose Your personal
            information for any other purpose with Your consent.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          RETENTION OF YOUR PERSONAL DATA
        </h2>

        <p className="mb-4">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>

        <p className="mb-4">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          TRANSFER OF YOUR PERSONAL DATA
        </h2>

        <p className="mb-4">
          Your information, including Personal Data, is processed at the
          Company&#39;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to — and maintained on — computers
          located outside of Your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than those from
          Your jurisdiction.
        </p>

        <p className="mb-4">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p className="mb-4">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          DELETE YOUR PERSONAL DATA
        </h2>

        <p className="mb-4">
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>

        <p className="mb-4">
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </p>
        <p className="mb-4">
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p className="mb-4">
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>

        <h2 className="text-lg font-semibold mb-4 text-blue-400">
          DISCLOSURE OF YOUR PERSONAL DATA
        </h2>

        <h2 className="text-md font-semibold mb-4 text-blue-400">
          Business Transactions
        </h2>

        <p className="mb-4">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <h2 className="text-md font-semibold mb-4 text-blue-400">
          Law enforcement
        </h2>

        <p className="mb-4">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>

        <h2 className="text-md font-semibold mb-4 text-blue-400">
          Other legal requirements
        </h2>

        <p className="mb-4">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>

        <ul className="list-disc pl-6 mt-6">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <h2 className="text-md font-semibold my-4 text-blue-400 underline">
          SECURITY OF YOUR PERSONAL DATA
        </h2>

        <p className="mb-4 text-blue-400 text-center">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>

        <h1 className="text-2xl font-semibold my-6 underline">
          CHILDREN&#39;S PRIVACY
        </h1>

        <p className="mb-4">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p className="mb-4">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent&#39;s consent before We collect and use that
          information.
        </p>

        <h1 className="text-2xl font-semibold my-6 underline">
          LINKS TO OTHER WEBSITES
        </h1>

        <p className="mb-4">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&#39;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </p>
        <p className="mb-4">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <h2 className="text-md font-semibold my-4 text-blue-400 ">
          GRIEVANCE REDRESSAL
        </h2>
        <p className="mb-4">
          Redressal Mechanism: Any complaints, abuse or concerns with regards to
          content and or comment or breach of these terms shall be immediately
          informed to the designated Grievance Officer as mentioned below via in
          writing or through email signed with the electronic signature
          to MakeAssure. (&quot;Grievance Officer&quot;).
        </p>

        <p className="mt-4">Extra DISCLAIMER:</p>
        <p className="mb-4">
          Clients utilizing any of MakeAssure administration across the
          accompanying medium for example through web (www.makeassure.com) ,
          Apps, SMS, telephone or some other mediums are limited by this extra
          disclaimer wherein they are forewarned to make appropriate enquiry
          before they (Users) depend, follow up on or go into any exchange (any
          sort or such an exchange including yet not restricted to financial
          exchange) with the Advertiser recorded with MakeAssure. All the Users
          are advised that all and any data of at all nature gave or got from
          the Advertiser/s is taken in accordance with some basic honesty,
          without least associating the bonafides with the Advertiser/s and
          MakeAssure doesn&#39;t affirm, doesn&#39;t recognize, or buy in to the
          cases and portrayal made by the Advertiser/s recorded with MakeAssure.
          Further MakeAssure isn&#39;t at all liable for any demonstration of
          Advertiser/s recorded at MakeAssure.
        </p>

        <p className="mt-4">The most effective method to report a posting</p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            If you have a decent confidence conviction that your IP right has
            been encroached by any of our distributer or any other person on
            MakeAssure&#39;s entry, you may follow the beneath cycle and/or look
            for Legal counsel.
          </li>
          <li>
            We necessitate that the Intellectual Property right proprietor or
            approved agent give the accompanying subtleties and email to the
            previously mentioned email id.
          </li>
          <li>Product/Service of a specific endorser/promoter.</li>
        </ul>

        <p className="mt-4">The email ought to contain the beneath data.</p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            Identification or depiction of the Intellectual Property Rights that
            has been encroached.
          </li>
          <li>
            Unambiguous ID or portrayal of where the material that you guarantee
            is encroaching is situated on MakeAssure entry with sufficient
            specifics. Item ID/site connections of encroaching items.
          </li>
          <li>Your location, phone number, and email address.</li>
          <li>
            An explanation by you, that the data in your notification is exact
            and that you are the copyright or licensed innovation proprietor or
            approved to follow up on the copyright or protected innovation
            proprietor&#39;s sake.
          </li>
          <li>
            Brand/Trade Name (if there should be an occurrence of Trademark
            encroachment)
          </li>
          <li>
            Details of the licensed innovation being encroached (Provide
            copyrighted pictures or brand name authentications as connections)
          </li>
          <li>
            Details/archives of legitimate procedures started against the
            substances (encroaching the Intellectual Property privileges of the
            proprietor).
          </li>
          <li>Grievance Redressal strategy.</li>
          <li>
            MakeAssure will make every essential step or potentially eliminate
            the encroached item/subtleties from its entry within 7 (Seven)
            working days subject to check of the subtleties as given above and
            the idea of grumbling.
          </li>
        </ul>

        <p className="mt-4">Lethargic ACCOUNTS:</p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            If there is no movement in your &quot;Make Assure&quot; Account
            (counting access or instalment exchanges) for a time of one (1)
            year, we may close your &quot;Make Assure&quot; Account.
          </li>
        </ul>

        <p className="mt-4 text-center">ANNEXURE &#39;A&#39;</p>
        <p className="mt-4">
          Restricted/Prohibited rundown of Products and Services alluded to in
          this Agreement is as referenced thus underneath:-
        </p>

        <ul className="list-disc pl-6 mt-6">
          <li>
            Adult merchandise and ventures which incorporates erotic
            entertainment and other explicitly intriguing materials (counting
            writing, symbolism and other media), escort or prostitution
            administrations; site access as well as site enrolments of porn or
            illicit locales.
          </li>
          <li>
            Alcohol which incorporates Alcohol or mixed drinks, &quot;for
            example, brew, alcohol, wine, or champagne.&quot;
          </li>
          <li>Body parts which incorporate organs or other body parts.</li>
          <li>
            Bulk advertising instruments which incorporate email records,
            programming, or different items empowering spontaneous email
            messages (spam).
          </li>
          <li>
            Cable descramblers and secret elements which incorporates gadgets
            expected to get link and satellite signals for nothing.
          </li>
          <li>
            Child sexual entertainment which incorporates obscene materials
            including minors.
          </li>
          <li>
            Copyright opening gadgets which incorporate Mod chips or different
            gadgets intended to bypass copyright security.
          </li>
          <li>
            Copyrighted media which incorporates unapproved duplicates of books,
            music, motion pictures, and other authorized or secured materials.
          </li>
          <li>
            Copyrighted programming which incorporates unapproved duplicates of
            programming, computer games and other authorized or ensured
            materials, including OEM or packaged programming.
          </li>
          <li>
            Counterfeit and unapproved merchandise which incorporates
            reproductions or impersonations of planner products; things without
            a superstar underwriting that would regularly require such an
            affiliation; counterfeit signatures, fake stamps, and other
            conceivably unapproved merchandise.
          </li>
          <li>
            Drugs and medication stuff which incorporates illicit medications
            and medication extras, including natural medications like salvia and
            sorcery mushrooms.
          </li>
          <li>
            Drug test circumvention helps which incorporates drug purifying
            shakes, pee test added substances, and related things.
          </li>
          <li>
            Endangered species which incorporates plants, creatures or different
            life forms (counting item subordinates) at risk for eradication.
          </li>
          <li>
            Gaming/betting which incorporates lottery tickets, sports wagers,
            participations/enlistment in internet betting destinations, and
            related substance.
          </li>
          <li>
            Government IDs or records which incorporates counterfeit IDs,
            identifications, confirmations, and respectable title.
          </li>
          <li>
            Hacking and breaking materials which incorporate manuals, how-to
            aides, data, or hardware empowering illicit admittance to
            programming, workers, site, or other secured property.
          </li>
          <li>
            Illegal merchandise which incorporates materials, items, or data
            advancing unlawful products or empowering illicit acts.
          </li>
          <li>
            Miracle fixes which incorporate unverified fixes, cures or different
            things advertised as speedy wellbeing fixes.
          </li>
          <li>
            Offensive merchandise which incorporates writing, items or different
            materials that:
            <div className="ml-9">
              <li>a. Defame or criticism any individual or gatherings</li>
              of individuals dependent on race, identity, public beginning,
              religion, sex, or different components
              <li>b. Encourage or prompt fierce acts </li>
              <li> c. Promote bigotry or disdain.</li>
            </div>
          </li>
          <li>
            Offensive merchandise, wrongdoing which incorporates wrongdoing
            scene photographs or things, for example, individual assets, related
            with crooks.
          </li>
          <li>
            Prescription medications or natural medications or any sort of
            online drug stores which incorporates drugs or different items
            requiring a remedy by an authorized clinical professional.
          </li>
          <li>
            Pyrotechnic gadgets, combustibles, corrosives and risky materials
            which incorporates explosives, firecrackers and related merchandise;
            harmful, combustible, and radioactive materials and substances.
          </li>
          <li>
            Regulated products which incorporate air packs; batteries containing
            mercury; Freon or comparable substances/refrigerants;
            compound/modern solvents; government regalia; vehicle titles; tags;
            police identifications and law implementation gear; lock-picking
            gadgets; pesticides; postage meters; reviewed things; gambling
            machines; observation hardware; merchandise managed by government or
            other office particulars.
          </li>
          <li>
            Securities which incorporate stocks, securities, or related monetary
            items.
          </li>
          <li>
            Tobacco and cigarettes which incorporates cigarettes, stogies,
            biting tobacco, and related items.
          </li>
          <li>
            Traffic gadgets which incorporate radar locators/jammers, tag
            covers, traffic light transformers, and related items.
          </li>
          <li>
            Weapons which incorporate guns, ammo, blades, knuckle
            reinforcements, firearm parts, and different deadly implements.
          </li>
          <li>
            Wholesale money which incorporates limited monetary forms or cash
            trades.
          </li>
          <li>
            Live creatures or stows away/skins/teeth, nails and different parts
            and so forth of creatures.
          </li>
          <li>Multi-Level Marketing assortment charges.</li>
          <li>
            Matrix destinations or locales utilizing a grid conspire approach.
          </li>
          <li>Work-at-home methodology as well as Work-at-home data.</li>
          <li>
            Any item or administration which isn&#39;t in consistence with every
            single appropriate law and guidelines whether government, state,
            nearby or worldwide incorporates the laws of India.
          </li>
          <li>
            Merchant offering types of assistance that have the capability of
            projecting &quot;Make Assure&quot; as well as Payment System
            Providers in a helpless light or potentially that might be inclined
            to ―Buy and Deny‖ disposition of the cardholders when charged (for
            example Grown-up material/develop content/Escort
            administrations/companion locaters) and in this manner prompting
            chargeback and misrepresentation misfortunes.
          </li>
          <li>
            Businesses or site that work inside the extent of laws which are not
            totally clear or are equivocal in nature (for example Electronic
            telephonic, site providing drugs or controlled substances, site that
            guarantee online match-production).
          </li>
          <li>
            Businesses out properly prohibited by law (for example Wagering and
            Gambling/distributions or substance that is probably going to be
            deciphered by the specialists as prompting moral turpitude or
            debauchery or induce standing/common pressures,
            lotteries/sweepstakes and rounds of possibility.)
          </li>
          <li>
            Merchant who bargains in theoretical merchandise/administrations
            (for example programming download/Health/Beauty Products), and
            organizations engaged with pyramid advertising plans or easy money
            scams. Some other item or Service, which in the sole assessment of
            either &quot;Make Assure &quot; or Payment System Providers, is
            inconvenient to the picture and interests of both of them/the two of
            them, as conveyed by both of them/the two of them to Merchant now
            and again. This will be without bias to some other terms and
            conditions referenced in this Agreement.
          </li>
          <li>
            Bulk promoting devices which incorporate email, records,
            programming, or different items empowering spontaneous email
            messages (spam).
          </li>
          <li>
            Web-based communication/SMS/Text/Facsimile administrations or
            Calling Cards. Transmission capacity or Data move/unified
            administrations. Voice measure/information measure administrations.
          </li>
          <li>Mailing records.</li>
        </ul>
        <p className="mt-4">LISTING FEES:</p>
        <p className="mt-4">
          The Advertiser consents to pay a non-refundable Listing Fee for the
          Services, which will be dictated by MakeAssure at the hour of going
          into this agreement. The (&quot;Listing Fee&quot;) will be paid by the
          Advertiser ahead of time all the while with the sign in of the
          Contract.
        </p>
        <p className="mt-4">Disclaimers:</p>
        <p className="mt-4">
          You utilize our administrations at your own danger. We are offering
          our types of assistance on a &quot;with no guarantees&quot; premise
          with no express or inferred guarantees, including, however not
          restricted to, guarantees of merchantability, readiness for a specific
          reason, title, non- encroachment, and independence from PC infection
          or other hurtful code. We don&#39;t warrant that any data gave by us
          is exact, finished, or helpful, that our administrations will be
          operational, mistake free, secure, or safe, or that our
          administrations will work without disturbances, deferrals, or flaws.
          We don&#39;t control, and are not answerable for, controlling how or
          when our clients utilize our administrations or the highlights,
          administrations, and interfaces our administrations give. We are not
          liable for and are not committed to control the activities or data
          (counting content) of our clients or other outsiders. You discharge
          us, our auxiliaries, subsidiaries, and our and their chiefs,
          officials, representatives, accomplices, and specialists from any
          case, grievance, reason for activity, debate, or question (together,
          &quot;guarantee&quot;) and harms, known and obscure, identifying with,
          emerging out of, or in any capacity associated with any such case you
          host against any third gatherings. You defer any rights you may have
          under any appropriate resolution or law of some other locale.
        </p>
        <p className="mt-4">
          Please note we don&#39;t inspect whether the any supplier of products
          or administrations is acceptable, legitimate or quality merchants of
          merchandise/specialist organizations. You should fulfil yourself
          pretty much all applicable viewpoints preceding benefiting of the
          terms of administration. We have additionally not arranged or talked
          about any terms of commitment with any of the supplier of merchandise
          or administrations. The equivalent ought to be finished by you. Buying
          of products or profiting of administrations from supplier will be at
          your own danger. We don&#39;t research, speak to or embrace the
          precision, lawfulness, authenticity, legitimacy or unwavering quality
          of any items, administrations, arrangements, coupons or different
          advancements or materials, including exhortation, evaluations, and
          suggestions contained on, conveyed through, or connected, downloaded
          or got to from the Platforms. We disavow all obligation or risk for
          the precision, content, culmination, legitimateness, dependability, or
          operability or accessibility of data or material, products or
          administrations showed in the indexed lists in the Platforms.
        </p>

        <h1 className="text-2xl font-semibold my-6 underline">
          Changes to this Privacy Policy
        </h1>
        <ul className="list-disc pl-6 mt-6">
          <li>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </li>
          <li>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </li>
          <li>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </li>
        </ul>

        <h1 className="text-2xl font-semibold my-6 underline">Contact Us</h1>
        <p className="mt-4">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul className="list-disc pl-6 mt-6">
          <li>By email: help@makeassure.com</li>
          <li>
            By visiting this page on our website:
            https://www.makeassure.com/contact_us
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
