import React from "react";

const AboutUs = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 underline flex justify-center items-center ">ABOUT US</h1>
        <div className="text-center bg-white">
          <p className="m-0 text-center leading-normal text-10.5pt">
            <strong className="font-open-sans text-gray-700">
              # Gives you a best value
            </strong>
            <strong className="font-open-sans text-gray-700">
              # Vocal for local
            </strong>
          </p>
          <br />
          <p className="m-0 leading-normal text-10.5pt">
            <strong className="font-open-sans text-gray-700">
              Grow your Brand, Business, Services and Products with MakeAssure
            </strong>
          </p>
          <p className="m-0 leading-normal text-10.5pt">&nbsp;</p>
          <p className="m-0 text-justify leading-normal text-10.5pt">
            <span className="font-open-sans text-gray-700">
              MakeAssure is an online platform for offline local marketplace
              that helps all kinds
            </span>
            <span className="font-open-sans text-gray-700">
              &nbsp;many types
            </span>
            <span className="font-open-sans text-gray-700">
              &nbsp;of goods and service provider. MakeAssure connects directly
              the consumers and the vendors (including service providers) within
              a particular geographical region. This serves local vendors to
              showcase their products, promotions and offers to the user
              community within the proximity.
            </span>
            <span className="font-open-sans text-gray-700">&nbsp;</span>
          </p>
          <p className="mt-5 text-justify leading-normal text-10.5pt">
            <span className="font-open-sans text-gray-700">
              The online channel focuses on providing a platform for small to
              large Business/Brand/Service products between Saler, Retailer,
              Dealer, Manufacturer, Supplier, Franchiser, Service provider and
              Buyers or Consumers through offline services from a local
              marketplace.
            </span>
          </p>
          <p className="mt-5 text-justify leading-normal text-10.5pt">
            <span className="font-open-sans text-gray-700">
              Our mission is to help you and your local offline business or
              services connect with larger platforms. We provide to you or your
              goods or services or needs though technical marketing needs. We
              aspire to provide you and your customers with a delightful
              experience on your behalf turning them to regulars.
            </span>
          </p>
          <p className="mt-5 text-justify leading-normal text-10.5pt">
            <span className="font-open-sans text-gray-700">
              MakeAssure facilitate to promote all/any kinds of legal products
              and services or brands in our platforms. Our services are very
              much available to users looking for a product/Service within a
              proximity/locality.
            </span>
          </p>
          <p className="mt-5 text-justify leading-normal text-10.5pt">
            <span className="font-open-sans text-gray-700">
              It helps Business or Service providers attract new customers and
              share information about what makes them special.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
