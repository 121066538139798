import React, { useState } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import WriteReview from "./WriteReview/WriteReview";
import { AiFillStar } from "react-icons/ai";
import Report from "../Report/Report";
import { useDispatch } from "react-redux";
import { loginModal } from "../../utils/appSlice";

const Review = ({
  postRatingHandler,
  reviewVenderSummaryData,
  setWriteReviewPopup,
  writeReviewPopup,
  venderData,
}) => {
  const [reviews, setReviews] = useState(false);
  const [writeReportPopup, setWriteReportPopup] = useState(false);
  const userToken = localStorage.getItem("userInfo");
  const userDetails = JSON.parse(userToken);
  const dispatch = useDispatch();

  const handleWriteReportPopup = () => {
    setWriteReportPopup(!writeReportPopup);
  };

  const toggleLoginHandler = () => {
    dispatch(loginModal());
  };

  return (
    <>
      <div className="subscription my-3 cursor-pointer rounded-md border border-gray-300 py-4 px-7">
        <div
          onClick={
            userDetails ? () => setReviews(!reviews) : toggleLoginHandler
          }
          className="flex items-center"
        >
          <h5 className="text-lg">Ratings</h5>
          {reviews ? (
            <IoIosArrowDropdownCircle
              className="ml-auto cursor-pointer"
              size={22}
              color="rgb(147 192 230)"
            />
          ) : (
            <MdOutlineArrowForwardIos className="ml-auto cursor-pointer" />
          )}
        </div>

        {reviews ? (
          <div className="p-10">
            <div className="mb-4 flex justify-end  xs:justify-center md:justify-end">
              <button
                onClick={() => setWriteReviewPopup(true)}
                className="rounded-md border border-blue-300 px-8 py-1 text-sm font-semibold text-blue-500"
              >
                Rate the Page
              </button>
            </div>
            {writeReviewPopup && (
              <WriteReview
                setWriteReviewPopup={setWriteReviewPopup}
                postRatingHandler={postRatingHandler}
              />
            )}
            <div className="">
              <div className="flex mb-3">
                <div className="flex">
                  Avg Rate:
                  {Array.from({ length: 5 }).map((_, index) => (
                    <AiFillStar
                      key={index}
                      className={`w-5 h-5 ${
                        index < reviewVenderSummaryData?.averageRating
                          ? "text-yellow-400"
                          : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>

                <p className=" text-sm font-medium text-gray-900 ">
                  (
                  {reviewVenderSummaryData?.averageRating === "NaN" ? (
                    <>0 ⭐</>
                  ) : (
                    <>{reviewVenderSummaryData?.averageRating} ⭐ </>
                  )}
                  out of 5 ⭐)
                </p>
              </div>
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                {reviewVenderSummaryData?.oneCount +
                  reviewVenderSummaryData?.twoCount +
                  reviewVenderSummaryData?.threeCount +
                  reviewVenderSummaryData?.fourCount +
                  reviewVenderSummaryData?.fiveCount +
                  " "}
                global ratings
              </p>
              <div className="flex items-center mt-4">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  5 star
                </span>
                <div className=" h-5 mx-4 flex justify-between bg-gray-200 rounded dark:bg-gray-700">
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                </div>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  {reviewVenderSummaryData?.fiveCount} persons
                </span>
              </div>
              <div className="flex items-center mt-4">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  4 star
                </span>
                <div className=" h-5 mx-4 flex justify-between bg-gray-200 rounded dark:bg-gray-700">
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                </div>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  {reviewVenderSummaryData?.fourCount} persons
                </span>
              </div>
              <div className="flex items-center mt-4">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  3 star
                </span>
                <div className=" h-5 mx-4 flex justify-between bg-gray-200 rounded dark:bg-gray-700">
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                </div>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  {reviewVenderSummaryData?.threeCount} persons
                </span>
              </div>
              <div className="flex items-center mt-4">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  2 star
                </span>
                <div className=" h-5 mx-4 flex justify-between bg-gray-200 rounded dark:bg-gray-700">
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                </div>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  {reviewVenderSummaryData?.twoCount} persons
                </span>
              </div>
              <div className="flex items-center mt-4">
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  1 star
                </span>
                <div className=" h-5 mx-4 flex justify-between bg-gray-200 rounded dark:bg-gray-700">
                  <AiFillStar className="w-5 h-5 text-yellow-400" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                  <AiFillStar className="w-5 h-5 text-gray-300" />
                </div>
                <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
                  {reviewVenderSummaryData?.oneCount} persons
                </span>
              </div>
            </div>

            <hr className="mt-8 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>

            <div className="mt-4 flex justify-end  xs:justify-center md:justify-end">
              <button
                onClick={handleWriteReportPopup}
                className="rounded-md border border-blue-300 px-8 py-1 text-sm font-semibold text-blue-500"
              >
                Report
              </button>
              {writeReportPopup ? (
                <Report
                  handleWriteReportPopup={handleWriteReportPopup}
                  venderData={venderData}
                />
              ) : null}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Review;
