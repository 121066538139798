import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../layouts/SideBar/Sidebar";
import Header from "../layouts/Header/Header";
import { closeMenu } from "../utils/appSlice";
import { useDispatch } from "react-redux";
import Logout from "../common/Logout/Logout";
import Login from "../pages/Log-in/Login";
import Notification from "../common/Notification/Notification";

const Body = () => {
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   tokenExpire();
  // }, []);

  // const tokenExpire = async () => {
  //   const userToken = localStorage.getItem("userInfo");
  //   const userDetails = JSON.parse(userToken);
  //   // setLoading(true);

  //   try {
  //     const auth = {
  //       headers: {
  //         Authorization: userDetails.token,
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/users/token-refresh?userId=${userDetails.customId}`,
  //       auth
  //     );

  //     const responseData = response.data;
  //     localStorage.removeItem("userInfo");
  //     navigate("/");
  //     toast.success("Successfully Logout");
  //   } catch (error) {
  //     console.log("Error fetching product lists:", error);
  //   }
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        dispatch(closeMenu());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Header />
      <Sidebar ref={sidebarRef} />
      <Login />
      <Logout />
      <Notification />
      <Outlet />
    </div>
  );
};

export default Body;
